import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Action } from "typescript-fsa";

import {
  asyncActionsPostForm,
  formActions,
  PostFormResponse
} from "../actions/contactPageActions";
import { ContactForm } from "../presentation/organisms/contactForm";
import { ContactPageState, FormValue } from "../states/contactPageState.ts";
import { AppState } from "../store";

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): DispatcherProps {
  return {
    initializeForm: () => dispatch(formActions.initializeForm()),
    changeForm: (formValue: FormValue) =>
      dispatch(formActions.changeFormValue(formValue)),
    sendForm: (formValues: FormValue) => {
      dispatch(asyncActionsPostForm.started({ params: {} }));
      fetch(
        "https://wp.kokiri.jp/wp-json/contact-form-7/v1/contact-forms/222/feedback/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=utf-8"
          },
          body: createFormData(formValues) // 本文のデータ型は "Content-Type" ヘッダーと一致する必要があります
        }
      )
        .then(response => response.json())
        .then((json: PostFormResponse) => {
          dispatch(
            asyncActionsPostForm.done({
              params: {},
              result: json
            })
          );
        })
        .catch((error: TypeError) => {
          dispatch(
            asyncActionsPostForm.failed({
              params: {},
              error
            })
          );
        });
    }
  };
}

function mapStateToProps(appState: AppState): StateProps {
  return {
    contactPageState: appState.contactPage
  };
}

export interface StateProps {
  contactPageState: ContactPageState;
}

interface DispatcherProps {
  initializeForm: () => void;
  sendForm: (formValue: FormValue) => void;
  changeForm: (formValue: FormValue) => void;
}

export class ContactPageContainer extends React.Component<
  StateProps & DispatcherProps
> {
  constructor(props: StateProps & DispatcherProps) {
    super(props);
  }

  componentDidMount(): void {
    this.props.initializeForm();
  }

  render() {
    const contactFormProps = {
      formState: this.props.contactPageState.formState,
      formValue: this.props.contactPageState.formValue,
      handleChangeNameForm: this.handleChangeNameForm,
      handleChangeEmailForm: this.handleChangeEmailForm,
      handleChangeSubjectForm: this.handleChangeSubjectForm,
      handleChangeMessageForm: this.handleChangeMessageForm,
      handleClickSendButton: this.handleClickSendButton
    };
    return <ContactForm {...contactFormProps} />;
  }

  handleChangeNameForm = (e: any) => {
    const formValue = {
      ...this.props.contactPageState.formValue,
      name: "" + e.target.value
    };
    this.props.changeForm(formValue);
  };

  handleChangeEmailForm = (e: any) => {
    const formValue = {
      ...this.props.contactPageState.formValue,
      email: "" + e.target.value
    };
    this.props.changeForm(formValue);
  };

  handleChangeSubjectForm = (e: any) => {
    const formValue = {
      ...this.props.contactPageState.formValue,
      subject: "" + e.target.value
    };
    this.props.changeForm(formValue);
  };

  handleChangeMessageForm = (e: any) => {
    const formValue = {
      ...this.props.contactPageState.formValue,
      message: "" + e.target.value
    };
    this.props.changeForm(formValue);
  };

  handleClickSendButton = () => {
    this.props.sendForm(this.props.contactPageState.formValue);
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactPageContainer);

function createFormData(formValue: FormValue) {
  const requestStrings = [
    "yourName=" + formValue.name,
    "yourEmail=" + formValue.email,
    "yourSubject=" + formValue.subject,
    "yourMessage=" + formValue.message
  ];
  return requestStrings.join("&");
}
