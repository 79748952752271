import { reducerWithInitialState } from "typescript-fsa-reducers";
import {
  asyncActionsFetchContentOfAboutTeaKokiri,
  asyncActionsFetchImagesForSlider,
  asyncActionsFetchLineUpCategories,
  asyncActionsFetchLineUpItems,
  asyncActionsFetchSnsLinks,
  asyncActionsFetchTextForLeadSentence
} from "../actions/topPageActions";
import { convertStringToPrice } from "../utils/convertStringToPrice";
import { removeHtmlTags } from "../utils/htmlTagsUtils";

type ImagesForSlideData = string;

interface ImageForeSlider {
  imageUrls: ImagesForSlideData[];
  isFetching: boolean;
  isFetched: boolean;
}

interface LineUpCategory {
  id: number;
  commonKey: number;
  name: string;
  slug: string;
  iconImageUrl: string;
}

interface LineUpItem {
  id: number;
  name: string;
  commonKeys: number[];
  slug: string;
  imageUrl: string;
  optionImageUrls: string[];
  explanation: {
    prices: string[];
    description: string;
  };
}

interface ContentOfAboutTeaKokiri {
  title: string;
  subTitle: string;
  body: string;
}

interface SnsLink {
  name: string;
  url: string;
}

export interface TopPageState {
  imagesForSlider: ImageForeSlider;
  textForLeadSentence: string;
  lineUpCategories: LineUpCategory[];
  lineUpItems: LineUpItem[];
  contentOfAboutTeaKokiri: ContentOfAboutTeaKokiri;
  snsLinks: SnsLink[];
}

const initialContentOfAboutTeaKokiri = {
  title: "",
  subTitle: "",
  body: ""
};

const initialState: TopPageState = {
  imagesForSlider: { imageUrls: [], isFetched: false, isFetching: false },
  textForLeadSentence: "",
  lineUpCategories: [],
  lineUpItems: [],
  contentOfAboutTeaKokiri: initialContentOfAboutTeaKokiri,
  snsLinks: []
};

export const topPageReducer = reducerWithInitialState(initialState)
  /**
   * スライダーに表示する画像のURLの取得
   */
  .case(asyncActionsFetchImagesForSlider.started, state => {
    return Object.assign({}, state, {
      imagesForSlider: { ...state.imagesForSlider, isFetching: true }
    });
  })
  .case(asyncActionsFetchImagesForSlider.failed, state => {
    // ...
    return Object.assign({}, state, {
      imagesForSlider: { ...state.imagesForSlider, isFetching: false }
    });
  })
  .case(asyncActionsFetchImagesForSlider.done, (state, { result }) => {
    const imageUrls = result.map(data => {
      return data.acf.image;
    });
    const convertedResult = {
      imageUrls,
      isFetching: false,
      isFetched: true
    };
    return Object.assign({}, state, { imagesForSlider: convertedResult });
    // ...
  })

  /**
   * リード文の取得
   */
  .case(asyncActionsFetchTextForLeadSentence.started, state => {
    return Object.assign({}, state);
  })
  .case(asyncActionsFetchTextForLeadSentence.failed, state => {
    // ...
    return Object.assign({}, state);
  })
  .case(asyncActionsFetchTextForLeadSentence.done, (state, { result }) => {
    return Object.assign({}, state, {
      textForLeadSentence: result.content.rendered
    });
    // ...
  })

  /**
   * カテゴリ一覧の取得
   */
  .case(asyncActionsFetchLineUpCategories.started, state => {
    return Object.assign({}, state);
  })
  .case(asyncActionsFetchLineUpCategories.failed, state => {
    // ...
    return Object.assign({}, state);
  })
  .case(asyncActionsFetchLineUpCategories.done, (state, { result }) => {
    const convertedResult = result.map(data => {
      return {
        id: data.id,
        commonKey: data.id,
        name: data.name,
        slug: data.slug,
        iconImageUrl: data.acf.icon_image
      };
    });
    return Object.assign({}, state, { lineUpCategories: convertedResult });
    // ...
  })

  /**
   * 商品一覧の取得
   */
  .case(asyncActionsFetchLineUpItems.started, state => {
    return Object.assign({}, state);
  })
  .case(asyncActionsFetchLineUpItems.failed, state => {
    // ...
    return Object.assign({}, state);
  })
  .case(asyncActionsFetchLineUpItems.done, (state, { result }) => {
    const convertedResult = result.map(data => {
      const prices = [5, 10, 15].reduce(
        (prevExplanations: string[], pieceNo: number): string[] => {
          const key = pieceNo + "_package_price";
          if (
            key === "5_package_price" ||
            key === "10_package_price" ||
            key === "15_package_price"
          ) {
            const explanation =
              data.acf && data.acf[key]
                ? pieceNo + "包" + "  ¥" + data.acf[key]
                : "";
            if (explanation !== "") {
              const price = convertStringToPrice(explanation);
              prevExplanations.push(price);
            }
          }
          return prevExplanations;
        },
        []
      );
      const description =
        data.acf && data.acf.description
          ? removeHtmlTags(data.acf.description)
          : "";
      return {
        id: data.id,
        name: data.title.rendered,
        commonKeys: data.product_category,
        slug: data.slug,
        imageUrl: data.acf.tea_leaf_image,
        optionImageUrls: [data.acf.tea_leaf_image],
        explanation: {
          prices,
          description
        }
      };
    });
    return Object.assign({}, state, { lineUpItems: convertedResult });
    // ...
  })

  /**
   * TeaKokiriについての内容の取得
   */
  .case(asyncActionsFetchContentOfAboutTeaKokiri.started, state => {
    return Object.assign({}, state);
  })
  .case(asyncActionsFetchContentOfAboutTeaKokiri.failed, state => {
    // ...
    return Object.assign({}, state);
  })
  .case(asyncActionsFetchContentOfAboutTeaKokiri.done, (state, { result }) => {
    const convertedResult = {
      title: result.title.rendered,
      subTitle:
        result.acf.sub_title && result.acf.sub_title.length > 0
          ? result.acf.sub_title
          : "",
      body: result.content.rendered
    };
    return Object.assign({}, state, {
      contentOfAboutTeaKokiri: convertedResult
    });
    // ...
  })

  /**
   * SNSリンクの取得
   */
  .case(asyncActionsFetchSnsLinks.started, state => {
    return Object.assign({}, state);
  })
  .case(asyncActionsFetchSnsLinks.failed, state => {
    // ...
    return Object.assign({}, state);
  })
  .case(asyncActionsFetchSnsLinks.done, (state, { result }) => {
    const convertedResult = result.map(data => {
      return {
        name: data.title.rendered,
        url: data.acf.url
      };
    });
    return Object.assign({}, state, { snsLinks: convertedResult });
    // ...
  });
