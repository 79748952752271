import React, { ReactElement } from "react";
import { FooterInner, FooterOuter } from "./element";
import {
  FlexInner,
  FlexOuter,
  NavigationOuter,
  SiteNameOuter
} from "./element";

interface Props {
  renderSiteNameLogo: () => ReactElement<any>;
  renderTextNavigation: () => ReactElement<any>;
  renderCopyRight: () => ReactElement<any>;
}

export const DesktopMiddleFooterTemplate = (props: Props) => {
  return (
    <FooterOuter>
      <FooterInner>
        <FlexOuter>
          <FlexInner>
            <SiteNameOuter>{props.renderSiteNameLogo()}</SiteNameOuter>
            <NavigationOuter>{props.renderTextNavigation()}</NavigationOuter>
          </FlexInner>
        </FlexOuter>
        <FlexOuter>{props.renderCopyRight()}</FlexOuter>
      </FooterInner>
    </FooterOuter>
  );
};
