import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement } from "react";
import { NavigationItem } from "../../../domain/NavigationItems";
import { Text } from "../../atoms/text/";
import { StyledA, StyledLi, StyledLink, StyledUl } from "./element";

interface Props {
  items: NavigationItem[];
  hasSeparater?: boolean;
  color?: string;
}

export const TextNavigation = (props: Props) => {
  const NavigationItems = props.items.map((item, index) => {
    // facebookとinstagramの時はアイコンで表示する
    let title: ReactElement<any>;
    switch (item.title.toLowerCase()) {
      case "instagram":
        title = (
          <StyledA href={item.slug} color={props.color}>
            <FontAwesomeIcon icon={["fab", "instagram"]} />
          </StyledA>
        );
        break;

      case "facebook":
        title = (
          <StyledA href={item.slug} color={props.color}>
            <FontAwesomeIcon icon={["fab", "facebook"]} />
          </StyledA>
        );
        break;

      default:
        title = (
          <StyledLink to={item.slug} color={props.color}>
            {item.title}
          </StyledLink>
        );
    }
    return (
      <>
        <StyledLi key={index}>{title}</StyledLi>
        {props.hasSeparater && !(index === props.items.length - 1) ? (
          <Text color={props.color}>|</Text>
        ) : (
          <></>
        )}
      </>
    );
  });
  return <StyledUl>{NavigationItems}</StyledUl>;
};
