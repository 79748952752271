import React from "react";

import scatteredHerbsImage from "../../../img/how_to_drink_herb_tea.jpg";
import teacupImage from "../../../img/mint.png";
import teaLeavesOnMatImage from "../../../img/teaLeavesOnMatImage.png";
import { ImgOuter, QuadrateImgOuter } from "./element";

interface Props {
  width?: string;
  imageUrl?: string;
}

interface QuadrateImageProps {
  width?: number;
  imageUrl?: string;
}

export const ScatteredHerbsImage = (props: Props) => (
  <ImgOuter src={scatteredHerbsImage} width={props.width} />
);
export const TeacupImage = (props: Props) => (
  <ImgOuter src={teacupImage} width={props.width} />
);

export const TeaLeavesOnMatImage = (props: Props) => (
  <ImgOuter src={teaLeavesOnMatImage} width={props.width} />
);

export const Image = (props: Props) => (
  <ImgOuter src={props.imageUrl} width={props.width} />
);

export const QuadrateImage = (props: QuadrateImageProps) => (
  <QuadrateImgOuter src={props.imageUrl} width={props.width} />
);
