import React from "react";
import MediaQuery from "react-responsive";
import SalesActivityPageContainer from "../../containers/salesActivityPageContainer";
import { DesktopFullDefaultTemplate } from "../templates/defaultPageTemplates/desktopFullDefaultPageTemplate";
import { DesktopMiddleDefaultTemplate } from "../templates/defaultPageTemplates/desktopMiddleDefaultPageTemplate";
import { DesktopSmallDefaultTemplate } from "../templates/defaultPageTemplates/desktopSmallDefaultPageTemplate";

export const SalesActivityPage = () => {
  return (
    <>
      <MediaQuery minWidth={1224}>
        <DesktopFullDefaultTemplate
          renderStoreInformation={() => <SalesActivityPageContainer />}
        />
      </MediaQuery>

      <MediaQuery minWidth={971} maxWidth={1223}>
        <DesktopMiddleDefaultTemplate
          renderStoreInformation={() => <SalesActivityPageContainer />}
        />
      </MediaQuery>

      <MediaQuery maxWidth={970}>
        <DesktopSmallDefaultTemplate
          renderStoreInformation={() => <SalesActivityPageContainer />}
        />
      </MediaQuery>
    </>
  );
};
