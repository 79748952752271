import styled from "styled-components";

export const FlexOuter = styled.div`
  width: 100%;
  padding: 10px 0 0 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
`;

export const FlexInner = styled.div`
  padding: 0;
  width: 950px;
  position: relative;
`;
