import actionCreatorFactory from "typescript-fsa";

const actionCreator = actionCreatorFactory();

type Params = object;

export interface TermsOfServiceResponse {
  title: { rendered: string };
  content: { rendered: string };
}

export const asyncActionsFetchTermsOfService = actionCreator.async<
  Params,
  TermsOfServiceResponse,
  Error
>("ACTIONS_FETCH_TERMS_OF_SERVICE");
