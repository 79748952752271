import React, { ReactElement } from "react";
import { FullOuter } from "./element";

interface Props {
  renderTopPage: () => ReactElement<any>;
}

export const DesktopTopPageTemplate = (props: Props) => {
  return <>{props.renderTopPage()}</>;
};

export const DesktopFullTopPageTemplate = (props: Props) => {
  return (
    <FullOuter>
      <>{props.renderTopPage()}</>
    </FullOuter>
  );
};
