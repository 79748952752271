import styled from "styled-components";
export const Outer = styled.div`
  width: 100%;

  position: relative;
`;

export const BackgroundImageOuter = styled.div``;
export const FrontImageOuter = styled.div`
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  min-width: 100%;
  display: inline-block;
  text-align: center;
`;

export const TextOuter = styled.div`
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  min-width: 100%;
  display: inline-block;
  text-align: center;
  text-shadow: 1px 1px 0 #fff, -1px 1px 0 #fff, 1px -1px 0 #fff,
    -1px -1px 0 #fff;
`;
