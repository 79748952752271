import actionCreatorFactory from "typescript-fsa";

const actionCreator = actionCreatorFactory();

export const gameActions = {
  updateGameState: actionCreator<number>("ACTIONS_FETCH_HISTORY")
};

type Params = object;
type History = Array<{ squares: Array<string | null> }>;

export const gameAsyncActions = actionCreator.async<Params, History, Error>(
  "ACTIONS_GET_HISTORY"
);
