import actionCreatorFactory from "typescript-fsa";

const actionCreator = actionCreatorFactory();

type Params = object;
interface FooterNavigationMenuResponse {
  items: Array<{ id: number; title: string; url: string }>;
}

export const asyncActionsFetchNavigationMenu = actionCreator.async<
  Params,
  FooterNavigationMenuResponse,
  Error
>("ACTIONS_FETCH_NAVIGATION_MENU");
