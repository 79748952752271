import React from "react";

import logo from "../../../img/tea_kokiri_logo.png";
import logoWhite from "../../../img/tea_kokiri_logo_white.png";
import { ImgOuter } from "./element";

interface Props {
  width?: number;
}

export const SiteNameLogo = (props: Props) => (
  <ImgOuter src={logo} width={props.width} />
);

export const SiteNameLogoWhite = (props: Props) => (
  <ImgOuter src={logoWhite} width={props.width} />
);
