import React from "react";
import styled from "styled-components";

export interface Props {
  isActive?: boolean;
}

export const Outer = styled.div`
  cursor: pointer;
  font-size: 16px;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 300;
  padding: 0 10px;
  margin-bottom: 0;
  display: inline-flex;
  text-align: center;
  border-radius: 5px;
  border: solid 1px
    ${(props: Props) => `${props.isActive ? "#ffffff" : "#d1bada"}`};
  color: ${(props: Props) => `${props.isActive ? "#ffffff" : "#d1bada"}`};
  background-color: ${(props: Props) =>
    `${props.isActive ? "#d1bada" : "#ffffff"}`};
`;
