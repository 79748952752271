import styled from "styled-components";

export const FlexOuter = styled.div`
  display: flex;
  justify-content: space-around;
  background-color: #fefcf0;
  padding: 10px;
  border-radius: 20px;
  margin: 0 auto 26px;
  width: 90%;
`;

export const FlexInner = styled.div`
  width: 80%;
`;
