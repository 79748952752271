import styled from "styled-components";

interface Props {
  isVertical: boolean;
}

// @ts-ignore
export const FlexOuter = styled.div`
  display: ${(props: Props) => (props.isVertical ? "box" : "flex")};
  justify-content: space-around;
  background-color: #fefcf0;
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 26px;
`;

export const FlexInner = styled.div`
  width: ${(props: Props) => (props.isVertical ? "90%" : "45%")};
  margin: 0 auto;
`;
