import React from "react";
import styled from "styled-components";

interface Props {
  width?: string;
}
interface QuadrateImgOute {
  width?: number;
}

export const ImgOuter = styled.img`
  width: ${(props: Props) => `${props.width ? props.width : "100%"}`};
`;

export const QuadrateImgOuter = styled.img`
  width: ${(props: QuadrateImgOute) =>
    `${props.width ? props.width : "100"}`}vw;
  height: ${(props: QuadrateImgOute) =>
    `${props.width ? props.width : "100"}`}vw;
  object-fit: cover;
`;
