import actionCreatorFactory from "typescript-fsa";

const actionCreator = actionCreatorFactory();

type Params = object;

export type ShippingCostResponse = Array<{
  title: { rendered: string };
  acf: { body: string };
}>;

export const asyncActionsFetchShippingCost = actionCreator.async<
  Params,
  ShippingCostResponse,
  Error
>("ACTIONS_FETCH_SHIPPING_COST");
