import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { withWindowSizeListener } from "react-window-size-listener";
import FooterContainer from "./containers/footerContainer";
import HeaderContainer from "./containers/headerContainer";
import "./index.css";

import { AboutPage } from "./presentation/pages/aboutPage";
import { ContactPolicyPage } from "./presentation/pages/contactPage";
import { PrivacyPolicyPage } from "./presentation/pages/privacyPolicyPage";
import { SalesActivityPage } from "./presentation/pages/salesActivityPage";
import { ShippingCostPage } from "./presentation/pages/shippingCostPage";
import { StoreInformationPage } from "./presentation/pages/storeInformationPage";
import { TermsOfServicePage } from "./presentation/pages/termsOfServicePage";
import { TopPage } from "./presentation/pages/topPage";

export interface WindowSizeType {
  windowWidth: number;
  windowHeight: number;
}

class App extends React.Component<{
  windowSize: WindowSizeType;
}> {
  render() {
    return (
      <div className="App">
        <Router>
          <>
            <HeaderContainer />
            <Route
              exact={true}
              path="/"
              render={() => <TopPage {...this.props} />}
            />
            <Route
              exact={true}
              path="/store_information"
              render={() => <StoreInformationPage />}
            />
            <Route
              exact={true}
              path="/shipping_cost"
              render={() => <ShippingCostPage />}
            />
            <Route
              exact={true}
              path="/terms_of_service"
              render={() => <TermsOfServicePage />}
            />
            <Route
              exact={true}
              path="/privacy_policy"
              render={() => <PrivacyPolicyPage />}
            />
            <Route
              exact={true}
              path="/contact"
              render={() => <ContactPolicyPage />}
            />
            <Route
              exact={true}
              path="/sales_activity"
              render={() => <SalesActivityPage />}
            />
            <Route exact={true} path="/about" render={() => <AboutPage />} />
            <FooterContainer />
          </>
        </Router>
      </div>
    );
  }
}
// @ts-ignore
export default withWindowSizeListener(App);
