import { unstable_useMediaQuery as useMediaQuery } from "@material-ui/core/useMediaQuery";
import React from "react";
import { WindowSizeType } from "../../../App";
import { ItemInformations } from "../itemInformations";
import { SimpleSidebar } from "../simpleSidebar";
import { ItemInformationsOuter, Outer, SideBarOuter } from "./element";

export interface Props {
  sidebarItems: SidebarItem[];
  mainItems: MainItem[];
  sideBarWidthRate: number;
  activeItemNum?: number;
  handleClickSideBarItem: (SideBarItemNum: number) => void;
  fontSizeOfSideBar: number;
  fontSizeOfItemName: number;
  fontSizeOfItemPrice: number;
  fontSizeOfItemDescription: number;
  col: number;
  windowSize?: WindowSizeType;
}

export interface SidebarItem {
  name: string;
  iconImageUrl: string;
  commonKey: number;
}

export interface MainItem {
  name: string;
  commonKeys: number[];
  imageUrl: string;
  explanation: {
    prices: string[];
    description: string;
  };
}

export const ItemInformationsWithSideBar = (props: Props) => {
  const outerWidthRate =
    props.windowSize && +props.windowSize.windowWidth > 1024
      ? 1024 / +props.windowSize.windowWidth
      : 1;
  const filteredItems = props.mainItems.filter(
    mainItem =>
      mainItem.commonKeys &&
      mainItem.commonKeys.some(commonKey => commonKey === props.activeItemNum)
  );
  const items = props.sideBarWidthRate > 0 ? filteredItems : props.mainItems;

  return (
    <Outer>
      {props.sideBarWidthRate > 0 && (
        <SideBarOuter sideBarWidthRate={props.sideBarWidthRate}>
          <SimpleSidebar
            fontSize={props.fontSizeOfSideBar}
            items={props.sidebarItems}
            activeItemNum={props.activeItemNum}
            handleClickItem={props.handleClickSideBarItem}
          />
        </SideBarOuter>
      )}
      <ItemInformationsOuter sideBarWidthRate={props.sideBarWidthRate}>
        <ItemInformations
          widthRate={
            outerWidthRate *
            (props.sideBarWidthRate || props.sideBarWidthRate === 0
              ? 100 - props.sideBarWidthRate
              : 90)
          }
          items={items}
          fontSizeOfItemName={props.fontSizeOfItemName}
          fontSizeOfItemPrice={props.fontSizeOfItemPrice}
          fontSizeOfItemDescription={props.fontSizeOfItemDescription}
          col={props.col}
        />
      </ItemInformationsOuter>
    </Outer>
  );
};
