import actionCreatorFactory from "typescript-fsa";

const actionCreator = actionCreatorFactory();

type Params = object;

export interface PrivacyPolicyResponse {
  title: { rendered: string };
  content: { rendered: string };
}

export const asyncActionsFetchPrivacyPolicy = actionCreator.async<
  Params,
  PrivacyPolicyResponse,
  Error
>("ACTIONS_FETCH_TERMS_OF_SERVICE");
