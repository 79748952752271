import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Action } from "typescript-fsa";
import {
  asyncActionsFetchShippingCost,
  ShippingCostResponse
} from "../actions/shippingCostPageActions";
import { ShippingCost } from "../presentation/organisms/shippingCost";
import { ShippingCostPageState } from "../states/shippingCostPageState";
import { AppState } from "../store";

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): DispatcherProps {
  return {
    getShippingCost: () => {
      dispatch(asyncActionsFetchShippingCost.started({ params: {} }));

      fetch("https://wp.kokiri.jp/wp-json/wp/v2/shipping_cost")
        .then(response => response.json())
        .then((json: ShippingCostResponse) => {
          dispatch(
            asyncActionsFetchShippingCost.done({
              params: {},
              result: json
            })
          );
        })
        .catch((error: TypeError) => {
          dispatch(
            asyncActionsFetchShippingCost.failed({
              params: {},
              error
            })
          );
        });
    }
  };
}

function mapStateToProps(appState: AppState): StateProps {
  return {
    shippingCostPageState: appState.shippingCostPage
  };
}

export interface StateProps {
  shippingCostPageState: ShippingCostPageState;
}

interface DispatcherProps {
  getShippingCost: () => void;
}

interface State {
  activeItemNum: null | number;
}

export class ShippingCostPageContainer extends React.Component<
  StateProps & DispatcherProps,
  State
> {
  constructor(props: StateProps & DispatcherProps) {
    super(props);
    this.state = {
      activeItemNum: null
    };
  }
  componentDidMount(): void {
    this.props.getShippingCost();
  }
  render() {
    return (
      <ShippingCost
        shippingCost={this.props.shippingCostPageState.shippingCost}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShippingCostPageContainer);
