import React from "react";

import MediaQuery from "react-responsive";
import { WindowSizeType } from "../../App";
import TopPageContainer from "../../containers/topPageContainer";
import {
  DesktopFullTopPageTemplate,
  DesktopTopPageTemplate
} from "../templates/topPageTemplates/desktopTopPageTemplate";

interface Props {
  windowSize: WindowSizeType;
}

export const TopPage = (props: Props) => {
  const desktopImageSliderSetting = {
    dots: false,
    infinite: true,
    arrows: false,
    fade: true,
    speed: 800,
    touchThreshold: 100,
    centerMode: true,
    centerPadding: "10%",
    autoplay: true,
    autoplaySpeed: 3000
  };
  const mobileImageSliderSetting = {
    dots: false,
    infinite: true,
    arrows: false,
    swipe: true,
    swipeToSlide: true,
    speed: 800,
    touchThreshold: 100,
    centerMode: true,
    centerPadding: "10%",
    autoplay: true,
    autoplaySpeed: 3000
  };
  return (
    <>
      {/*デスクトップ*/}
      <MediaQuery minDeviceWidth={769}>
        <MediaQuery minWidth={1025}>
          <DesktopFullTopPageTemplate
            renderTopPage={() => (
              <TopPageContainer
                fontSizeOfSideBar={20}
                fontSizeOfItemName={20}
                fontSizeOfItemPrice={18}
                fontSizeOfItemDescription={18}
                sideBarWidthRate={30}
                itemsCol={3}
                imageSliderSetting={desktopImageSliderSetting}
                hasMainImage={false}
                windowSize={props.windowSize}
              />
            )}
          />
        </MediaQuery>
        <MediaQuery maxWidth={1024} minWidth={769}>
          <DesktopTopPageTemplate
            renderTopPage={() => (
              <TopPageContainer
                fontSizeOfSideBar={16}
                fontSizeOfItemName={16}
                fontSizeOfItemPrice={14}
                fontSizeOfItemDescription={14}
                sideBarWidthRate={30}
                itemsCol={3}
                imageSliderSetting={desktopImageSliderSetting}
                hasMainImage={false}
                windowSize={props.windowSize}
              />
            )}
          />
        </MediaQuery>
        <MediaQuery maxWidth={768} minWidth={426}>
          <DesktopTopPageTemplate
            renderTopPage={() => (
              <TopPageContainer
                fontSizeOfSideBar={16}
                fontSizeOfItemName={14}
                fontSizeOfItemPrice={14}
                fontSizeOfItemDescription={14}
                sideBarWidthRate={0}
                itemsCol={3}
                imageSliderSetting={desktopImageSliderSetting}
                hasMainImage={false}
                windowSize={props.windowSize}
              />
            )}
          />
        </MediaQuery>
        <MediaQuery maxWidth={425}>
          <DesktopTopPageTemplate
            renderTopPage={() => (
              <TopPageContainer
                fontSizeOfSideBar={16}
                fontSizeOfItemName={12}
                fontSizeOfItemPrice={12}
                fontSizeOfItemDescription={12}
                sideBarWidthRate={0}
                itemsCol={2}
                imageSliderSetting={desktopImageSliderSetting}
                hasMainImage={false}
                windowSize={props.windowSize}
              />
            )}
          />
        </MediaQuery>
      </MediaQuery>
      {/*モバイル*/}
      <MediaQuery maxDeviceWidth={768} minDeviceWidth={426}>
        <DesktopTopPageTemplate
          renderTopPage={() => (
            <TopPageContainer
              fontSizeOfSideBar={16}
              fontSizeOfItemName={14}
              fontSizeOfItemPrice={14}
              fontSizeOfItemDescription={14}
              sideBarWidthRate={0}
              itemsCol={3}
              imageSliderSetting={mobileImageSliderSetting}
              hasMainImage={true}
              windowSize={props.windowSize}
            />
          )}
        />
      </MediaQuery>
      <MediaQuery maxDeviceWidth={425}>
        <DesktopTopPageTemplate
          renderTopPage={() => (
            <TopPageContainer
              fontSizeOfSideBar={16}
              fontSizeOfItemName={12}
              fontSizeOfItemPrice={12}
              fontSizeOfItemDescription={12}
              sideBarWidthRate={0}
              itemsCol={2}
              imageSliderSetting={mobileImageSliderSetting}
              hasMainImage={true}
              windowSize={props.windowSize}
            />
          )}
        />
      </MediaQuery>
    </>
  );
};
