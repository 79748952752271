import React from "react";

import { Title } from "../../atoms/title";

interface Props {
  privacyPolicy: {
    title: string;
    body: string;
  };
}

export const PrivacyPolicy = (props: Props) => {
  return (
    <>
      <Title
        isShowLinesOnBothSide={true}
        value={"プライバシーポリシー"}
        fontSize={28}
      />
      <span dangerouslySetInnerHTML={{ __html: props.privacyPolicy.body }} />
    </>
  );
};
