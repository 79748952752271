import styled from "styled-components";

export interface Props {
  sideBarWidthRate?: number;
}

export const Outer = styled.div`
  width: 100%;
  display: flex;
`;

export const SideBarOuter = styled.div`
  width: ${(props: Props) =>
    `${
      props.sideBarWidthRate || props.sideBarWidthRate === 0
        ? props.sideBarWidthRate
        : 10
    }`}%;
`;

export const ItemInformationsOuter = styled.div`
  width: ${(props: Props) =>
    `${
      props.sideBarWidthRate || props.sideBarWidthRate === 0
        ? 100 - props.sideBarWidthRate
        : 90
    }`}%;
`;
