import React from "react";

import { Title } from "../../atoms/title";
import { StoreInformation } from "../../molecules/storeInformation";

interface StoreInformationProps {
  name: string;
  address: string;
  tel: string;
  url: string;
}

interface Props {
  storeInformations: StoreInformationProps[];
}

export const StoreInformations = (props: Props) => {
  return (
    <>
      <Title
        isShowLinesOnBothSide={true}
        value={"お取り扱い店舗"}
        fontSize={28}
      />
      {props.storeInformations.map(
        (storeInformation: StoreInformationProps, index: number) => {
          return (
            <StoreInformation key={index} storeInformation={storeInformation} />
          );
        }
      )}
    </>
  );
};
