import styled from "styled-components";

export interface Props {
  itemsLength: number;
}
export const FlexOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px 0;
`;

export const FlexItemA = styled.a`
  width: ${(props: Props) =>
    props.itemsLength ? `${100 / props.itemsLength - 4}%` : "30%"};
  text-decoration: none;
  color: #000000;
`;
