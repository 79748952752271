import { createMuiTheme } from "@material-ui/core/styles";

export const MyTheme = createMuiTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#ab2bd9",
      dark: "#002884",
      contrastText: "#fff"
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000"
    }
  }
});
