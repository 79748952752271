import { reducerWithInitialState } from "typescript-fsa-reducers";
import { asyncActionsFetchStoreInformations } from "../actions/storeInformationPageActions";

interface StoreInformation {
  name: string;
  address: string;
  tel: string;
  url: string;
}

export interface StoreInformationPageState {
  storeInformations: StoreInformation[];
}

const initialState: StoreInformationPageState = {
  storeInformations: []
};

export const storeInformationPageReducer = reducerWithInitialState(initialState)
/**
 * 店舗情報のURLの取得
 */
  .case(asyncActionsFetchStoreInformations.started, state => {
    return Object.assign({}, state);
  })
  .case(asyncActionsFetchStoreInformations.failed, state => {
    // ...
    return Object.assign({}, state);
  })
  .case(asyncActionsFetchStoreInformations.done, (state, { result }) => {
    const convertedResult = result.map(data => {
      return {
        name: data.title.rendered,
        address: data.acf.address,
        tel: data.acf.tel,
        url: data.acf.url
      };
    });
    return Object.assign({}, state, { storeInformations: convertedResult });
    // ...
  });
