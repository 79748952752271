import React from "react";
import { TextInBoxForSnsLinksBox } from "../../molecules/textInBox";
import { FlexItemA, FlexOuter } from "./element";

export interface Props {
  items: Item[];
}

export interface Item {
  name: string;
  url: string;
}

export const SnsLinksBox = (props: Props) => {
  return (
    <FlexOuter>
      {props.items.map((item, index) => {
        return (
          <FlexItemA
            itemsLength={props.items.length}
            key={index}
            href={item.url}
          >
            <TextInBoxForSnsLinksBox
              text={item.name}
              fontSize={22}
              iconSize={"lg"}
            />
          </FlexItemA>
        );
      })}
    </FlexOuter>
  );
};
