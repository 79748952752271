import actionCreatorFactory from "typescript-fsa";

const actionCreator = actionCreatorFactory();

type Params = object;
export type ImagesForSliderResponse = Array<{ acf: { image: string } }>;

export const asyncActionsFetchImagesForSlider = actionCreator.async<
  Params,
  ImagesForSliderResponse,
  Error
>("ACTIONS_FETCH_IMAGES_FOR_SLIDER");

export interface TextForLeadSentenceResponse {
  content: { rendered: string };
}

export const asyncActionsFetchTextForLeadSentence = actionCreator.async<
  Params,
  TextForLeadSentenceResponse,
  Error
>("ACTIONS_FETCH_TEXT_FOR_LEAD_SENTENCE");

export type LineUpCategoriesResponse = Array<{
  id: number;
  slug: string;
  name: string;
  acf: { icon_image: string };
}>;

export const asyncActionsFetchLineUpCategories = actionCreator.async<
  Params,
  LineUpCategoriesResponse,
  Error
>("ACTIONS_FETCH_LINE_UP_CATEGORIES");

export type LineUpItemsResponse = Array<{
  id: number;
  slug: string;
  title: {
    rendered: string;
  };
  product_category: number[];
  acf: {
    "5_package_price": string;
    "10_package_price": string;
    "15_package_price": string;
    tea_leaf_image: { url: string };
    tea_in_cup_image: { url: string };
    description: string;
  };
}>;

export const asyncActionsFetchLineUpItems = actionCreator.async<
  Params,
  LineUpItemsResponse,
  Error
>("ACTIONS_FETCH_LINE_UP_ITEMS");

export interface ContentOfAboutTeaKokiriResponse {
  title: { rendered: string };
  acf: { sub_title: string };
  content: { rendered: string };
}

export const asyncActionsFetchContentOfAboutTeaKokiri = actionCreator.async<
  Params,
  ContentOfAboutTeaKokiriResponse,
  Error
>("ACTIONS_FETCH_CONTENT_OF_ABOUT_TEA_KOKIRI");

export type SnsLinksResponse = Array<{
  title: { rendered: string };
  acf: { url: string };
}>;

export const asyncActionsFetchSnsLinks = actionCreator.async<
  Params,
  SnsLinksResponse,
  Error
>("ACTIONS_FETCH_SBS_LINKS");
