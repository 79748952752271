import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Action } from "typescript-fsa";
import {
  AboutTeaKokiriResponse,
  asyncActionsFetchAboutTeaKokiri,
  asyncActionsFetchOwnersProfile,
  OwnersProfileResponse
} from "../actions/aboutPageActions";
import { ParagraphWithCenteredElements } from "../presentation/molecules/paragraphWithCenteredElements";
import { AboutPageState } from "../states/aboutPageState";
import { AppState } from "../store";
import { StateProps } from "./aboutPageContainer";

interface DispatcherProps {
  getAboutTeaKokiri: () => void;
  getOwnersProfile: () => void;
}

export interface StateProps {
  aboutPageState: AboutPageState;
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): DispatcherProps {
  return {
    getAboutTeaKokiri: () => {
      dispatch(asyncActionsFetchAboutTeaKokiri.started({ params: {} }));

      fetch("https://wp.kokiri.jp/wp-json/wp/v2/pages/134")
        .then(response => response.json())
        .then((json: AboutTeaKokiriResponse) => {
          // <- Biz Logic (Async)
          dispatch(
            asyncActionsFetchAboutTeaKokiri.done({ params: {}, result: json })
          ); // <- App Logic (Sync)
        })
        .catch((error: TypeError) => {
          dispatch(
            asyncActionsFetchAboutTeaKokiri.failed({
              params: {},
              error
            })
          );
        });
    },
    getOwnersProfile: () => {
      dispatch(asyncActionsFetchOwnersProfile.started({ params: {} }));

      fetch("https://wp.kokiri.jp/wp-json/wp/v2/pages/326")
        .then(response => response.json())
        .then((json: OwnersProfileResponse) => {
          // <- Biz Logic (Async)
          dispatch(
            asyncActionsFetchOwnersProfile.done({ params: {}, result: json })
          ); // <- App Logic (Sync)
        })
        .catch((error: TypeError) => {
          dispatch(
            asyncActionsFetchOwnersProfile.failed({
              params: {},
              error
            })
          );
        });
    }
  };
}

function mapStateToProps(appState: AppState): StateProps {
  return {
    aboutPageState: appState.aboutPage
  };
}

export class AboutOfServicePageContainer extends React.Component<
  StateProps & DispatcherProps
> {
  constructor(props: StateProps & DispatcherProps) {
    super(props);
  }

  componentDidMount(): void {
    this.props.getAboutTeaKokiri();
    this.props.getOwnersProfile();
  }

  render() {
    return (
      <>
        <ParagraphWithCenteredElements
          title={this.props.aboutPageState.aboutTeaKokiri.title}
          titleFontSize={28}
          subTitle={this.props.aboutPageState.aboutTeaKokiri.subTitle}
          subTitleFontSize={22}
          body={this.props.aboutPageState.aboutTeaKokiri.body}
          innerWidth={"80%"}
          gapHeight={10}
        />
        <ParagraphWithCenteredElements
          title={this.props.aboutPageState.ownersName.title}
          titleFontSize={28}
          subTitle={this.props.aboutPageState.ownersName.subTitle}
          subTitleFontSize={22}
          body={this.props.aboutPageState.ownersName.body}
          innerWidth={"80%"}
          gapHeight={10}
          subTitleAlign={"right"}
        />
        {/*<TeaLeavesOnMatImage width={"100%"} />*/}
        {/*<SnsLinksBox items={this.props.topPageState.snsLinks} />*/}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutOfServicePageContainer);
