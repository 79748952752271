import React from "react";
import { QuadrateImage } from "../../atoms/Image";
import { TextInBoxAlignLeft, TextInBoxAlignRight } from "../textInBox";

interface Props {
  name: string;
  imageUrl: string;
  imageWidth?: number;
  explanation: {
    prices: string[];
    description: string;
  };
  fontSizeOfItemName: number;
  fontSizeOfItemPrice: number;
  fontSizeOfItemDescription: number;
}

export const ItemInformationBox = (props: Props) => {
  return (
    <>
      <QuadrateImage imageUrl={props.imageUrl} width={props.imageWidth} />
      <TextInBoxAlignLeft
        text={props.name}
        fontSize={props.fontSizeOfItemName}
      />
      {props.explanation.prices.map((price, index) => {
        return (
          <TextInBoxAlignRight
            key={index}
            text={price}
            fontSize={props.fontSizeOfItemPrice}
          />
        );
      })}
      {props.explanation.description && (
        <TextInBoxAlignLeft
          text={props.explanation.description}
          fontSize={props.fontSizeOfItemDescription}
        />
      )}
    </>
  );
};
