import styled from "styled-components";

interface Props {
  fontSize?: number;
  isBold?: boolean;
  color?: string;
}

export const Text = styled.p`
  font-size: ${(props: Props) => (props.fontSize ? props.fontSize : 17)}px;
  color: ${(props: Props) => (props.color ? props.color : "#000000")};
  font-family: "M PLUS Rounded 1c";
  font-weight: ${(props: Props) => (props.isBold ? 500 : 300)};
  margin: 0;
`;
