import { reducerWithInitialState } from "typescript-fsa-reducers";
import { asyncActionsFetchTermsOfService } from "../actions/termsOfServicePageActions";

export interface TermsOfServicePageState {
  termsOfService: {
    title: string;
    body: string;
  };
}

const initialState: TermsOfServicePageState = {
  termsOfService: {
    title: "",
    body: ""
  }
};

export const termsOfServicePageReducer = reducerWithInitialState(initialState)
  /**
   * 利用規約のURLの取得
   */
  .case(asyncActionsFetchTermsOfService.started, state => {
    return Object.assign({}, state);
  })
  .case(asyncActionsFetchTermsOfService.failed, state => {
    // ...
    return Object.assign({}, state);
  })
  .case(asyncActionsFetchTermsOfService.done, (state, { result }) => {
    const convertedResult = {
      title: result.title.rendered,
      body: result.content.rendered
    };
    return Object.assign({}, state, { termsOfService: convertedResult });
    // ...
  });
