import React from "react";
import MediaQuery from "react-responsive";
import { ImageSlider } from "../../atoms/imageSlider";
import { DesktopFullTopPageImageSliderTemplate } from "../../templates/topPageImageSliderTemplates/desktopFullTopPageImageSliderTemplate";
import { DesktopMiddleTopPageImageSliderTemplate } from "../../templates/topPageImageSliderTemplates/desktopMiddleTopPageImageSliderTemplate";
import { DesktopSmallTopPageImageSliderTemplate } from "../../templates/topPageImageSliderTemplates/desktopSmallTopPageImageSliderTemplate";

export interface Props {
  images: string[];
  setting?: any;
}

export const TopPageImageSlider = (props: Props) => {
  return (
    <>
      <MediaQuery minWidth={1224}>
        <DesktopFullTopPageImageSliderTemplate
          renderTopPageImageSlider={() => (
            <ImageSlider images={props.images} setting={props.setting} />
          )}
        />
      </MediaQuery>

      <MediaQuery minWidth={971} maxWidth={1223}>
        <DesktopMiddleTopPageImageSliderTemplate
          renderTopPageImageSlider={() => (
            <ImageSlider images={props.images} setting={props.setting} />
          )}
        />
      </MediaQuery>

      <MediaQuery maxWidth={970}>
        <DesktopSmallTopPageImageSliderTemplate
          renderTopPageImageSlider={() => (
            <ImageSlider images={props.images} setting={props.setting} />
          )}
        />
      </MediaQuery>
    </>
  );
};
