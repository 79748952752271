import React from "react";

import { Text } from "../../atoms/text";
import { FlexInner, FlexOuter } from "./element";

interface Props {
  title: string;
  body: string;
}

export const ArticleCard = (props: Props) => {
  return (
    <FlexOuter>
      <FlexInner>
        <Text fontSize={26} isBold={true}>
          {props.title}
        </Text>
        {props.body
          .replace(/(<br>|<br \/>)/gi, "\n")
          .replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, "")
          .split("\n")
          .filter(str => str.length !== 0)
          .map((str, i) => {
            return <Text key={i}>{str}</Text>;
          })}
      </FlexInner>
    </FlexOuter>
  );
};
