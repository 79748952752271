import { reducerWithInitialState } from "typescript-fsa-reducers";
import {
  asyncActionsFetchFooterNavigationMenu,
  asyncActionsFetchSnsLinks
} from "../actions/footerActions";

interface FooterNavigationMenuData {
  title: string;
  slug: string;
}

export interface FooterState {
  footerNavigationMenu: FooterNavigationMenuData[];
  snsLinks: SnsLink[];
}

interface SnsLink {
  name: string;
  url: string;
}

const initialState: FooterState = {
  footerNavigationMenu: [],
  snsLinks: []
};

export const footerReducer = reducerWithInitialState(initialState)
  .case(asyncActionsFetchFooterNavigationMenu.started, state => {
    return Object.assign({}, state);
  })
  .case(asyncActionsFetchFooterNavigationMenu.failed, state => {
    // ...
    return Object.assign({}, state);
  })
  .case(asyncActionsFetchFooterNavigationMenu.done, (state, { result }) => {
    const convertedResult = result.items.map(data => {
      return {
        title: data.title,
        slug: data.url
      };
    });
    return Object.assign({}, state, { footerNavigationMenu: convertedResult });
    // ...
  })
  /**
   * SNSリンクの取得
   */
  .case(asyncActionsFetchSnsLinks.started, state => {
    return Object.assign({}, state);
  })
  .case(asyncActionsFetchSnsLinks.failed, state => {
    // ...
    return Object.assign({}, state);
  })
  .case(asyncActionsFetchSnsLinks.done, (state, { result }) => {
    const convertedResult = result.map(data => {
      return {
        name: data.title.rendered,
        url: data.acf.url
      };
    });
    return Object.assign({}, state, { snsLinks: convertedResult });
    // ...
  });
