import actionCreatorFactory from "typescript-fsa";

const actionCreator = actionCreatorFactory();

type Params = object;

export interface AboutTeaKokiriResponse {
  title: { rendered: string };
  acf: { sub_title: string };
  content: { rendered: string };
}

export const asyncActionsFetchAboutTeaKokiri = actionCreator.async<
  Params,
  AboutTeaKokiriResponse,
  Error
>("ACTIONS_FETCH_ABOUT_TEA_KOKIRI");

export interface OwnersProfileResponse {
  title: { rendered: string };
  acf: { name: string };
  content: { rendered: string };
}

export const asyncActionsFetchOwnersProfile = actionCreator.async<
  Params,
  OwnersProfileResponse,
  Error
>("ACTIONS_FETCH_OWNERS");
