import styled from "styled-components";

export const FormContentsOuter = styled.div`
  width: 80%;
  margin: 0 auto;
`;

export const SendButtonOuter = styled.div`
  text-align: center;
  padding: 1.5em 0;
`;

export const FormOuter = styled.div`
  position: relative;
`;

export const SendSuccessOuter = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #ffffff;
`;
