import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Action } from "typescript-fsa";

import {
  asyncActionsFetchPrivacyPolicy,
  PrivacyPolicyResponse
} from "../actions/privacyPolicyPageActions";
import { PrivacyPolicy } from "../presentation/organisms/privacyPolicy";
import { PrivacyPolicyPageState } from "../states/privacyPolicyPageState";
import { AppState } from "../store";

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): DispatcherProps {
  return {
    getPrivacyPolicy: () => {
      dispatch(asyncActionsFetchPrivacyPolicy.started({ params: {} }));

      fetch("https://wp.kokiri.jp/wp-json/wp/v2/pages/219")
        .then(response => response.json())
        .then((json: PrivacyPolicyResponse) => {
          dispatch(
            asyncActionsFetchPrivacyPolicy.done({
              params: {},
              result: json
            })
          );
        })
        .catch((error: TypeError) => {
          dispatch(
            asyncActionsFetchPrivacyPolicy.failed({
              params: {},
              error
            })
          );
        });
    }
  };
}

function mapStateToProps(appState: AppState): StateProps {
  return {
    privacyPolicyPageState: appState.privacyPolicyPage
  };
}

export interface StateProps {
  privacyPolicyPageState: PrivacyPolicyPageState;
}

interface DispatcherProps {
  getPrivacyPolicy: () => void;
}

export class PrivacyPolicyPageContainer extends React.Component<
  StateProps & DispatcherProps
> {
  constructor(props: StateProps & DispatcherProps) {
    super(props);
  }
  componentDidMount(): void {
    this.props.getPrivacyPolicy();
  }
  render() {
    console.log(this.props);
    return (
      <PrivacyPolicy
        privacyPolicy={this.props.privacyPolicyPageState.privacyPolicy}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivacyPolicyPageContainer);
