import styled from "styled-components";

export const HeaderOuter = styled.div`
  width: 100%;
  padding: 10px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderInner = styled.div`
  padding: 0;
  width: 950px;
  position: relative;
`;

export const SiteNameOuter = styled.div`
  left: 0px;
`;

export const NavigationOuter = styled.div`
  right: 0px;
  height: auto;
  bottom: 0px;
  padding: 0;
  position: absolute;
`;
