import styled from "styled-components";

export const RowOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-bottom: 20px;
`;

interface ItemOuterProps {
  col: number;
}

export const ItemOuter = styled.div`
  width: ${(props: ItemOuterProps) =>
    props.col ? (100 / props.col) * 0.9 + "%" : 50 + "%"};
`;
