import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledLink = styled(Link)``;

export const SiteNameOuter = styled.div`
  left: 0px;
`;

export const NavigationOuter = styled.div`
  right: 0px;
  height: auto;
  bottom: 0px;
  padding: 0;
  position: absolute;
`;
