import React from "react";

import { Text } from "../../atoms/text";
import { FlexInnerLeft, FlexInnerRight, FlexOuter, Img } from "./element";

import { unstable_useMediaQuery as useMediaQuery } from "@material-ui/core/useMediaQuery";
import { Event } from "../../../states/salesActivityPageState";

interface Props {
  event: Event;
}

export const EventCard = (props: Props) => {
  const date = new Date(props.event.times[0].day);

  const isMobile = useMediaQuery("(max-width:425px)");
  const isMobileDevice = useMediaQuery("( max-device-width: 425px)");
  const isVertical = isMobile || isMobileDevice;

  return (
    <>
      <FlexOuter isVertical={isVertical}>
        <FlexInnerLeft isVertical={isVertical}>
          <Img src={props.event.imageUrl} width="100%" height="200" />
        </FlexInnerLeft>
        <FlexInnerRight isVertical={isVertical}>
          <Text fontSize={26} isBold={true}>
            {props.event.eventName}
          </Text>
          <Text fontSize={24}>
            {`日程 : ${date.getFullYear()}年 ${date.getMonth() +
              1}月 ${date.getDate()}日`}
          </Text>
          {props.event.times[0].time !== "" && (
            <Text fontSize={24}>{`時間 : ${props.event.times[0].time}`}</Text>
          )}
          <Text fontSize={24}>{`場所 :  ${props.event.venue.name}`}</Text>
        </FlexInnerRight>
      </FlexOuter>
    </>
  );
};
