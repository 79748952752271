import { reducerWithInitialState } from "typescript-fsa-reducers";
import {
  asyncActionsPostForm,
  formActions
} from "../actions/contactPageActions";

export interface FormState {
  message: string;
  isFetching: boolean;
  isFetched: boolean;
  isFetchSuccess: boolean;
  isSendSuccess: boolean;
}

export interface FormValue {
  name: string;
  email: string;
  subject: string;
  message: string;
}

export interface ContactPageState {
  formState: FormState;
  formValue: FormValue;
}

const initialState: ContactPageState = {
  formState: {
    message: "",
    isFetching: false,
    isFetched: false,
    isFetchSuccess: false,
    isSendSuccess: false
  },
  formValue: {
    name: "",
    email: "",
    subject: "",
    message: ""
  }
};

export const contactPageReducer = reducerWithInitialState(initialState)
  /**
   * form送信後のメッセージ
   */
  .case(asyncActionsPostForm.started, (state: ContactPageState) => {
    const newFormState = {
      ...state.formState,
      isFetching: true
    };
    return Object.assign({}, state, newFormState);
  })
  .case(asyncActionsPostForm.failed, state => {
    const newState = {
      formState: { ...state.formState, isFetching: false, isFetched: true },
      formValue: {
        name: "",
        email: "",
        subject: "",
        message: ""
      }
    };
    return newState;
  })
  .case(asyncActionsPostForm.done, (state, { result }) => {
    console.log(result);
    const isSendSuccess = result.status === "mail_sent";
    const newState = {
      formState: {
        ...state.formState,
        message: result.message,
        isFetching: false,
        isFetched: true,
        isFetchSuccess: true,
        isSendSuccess
      },
      formValue: {
        name: isSendSuccess ? "" : state.formValue.name,
        email: isSendSuccess ? "" : state.formValue.email,
        subject: isSendSuccess ? "" : state.formValue.subject,
        message: isSendSuccess ? "" : state.formValue.message
      }
    };
    return newState;
    // ...
  })
  .case(formActions.changeFormValue, (state, formValue) => {
    const nextFormValue = {
      formValue
    };
    return Object.assign({}, state, nextFormValue);
    // ...
  })
  .case(formActions.initializeForm, state => {
    const newState = {
      formState: {
        message: "",
        isFetching: false,
        isFetched: false,
        isFetchSuccess: false,
        isSendSuccess: false
      },
      formValue: {
        name: "",
        email: "",
        subject: "",
        message: ""
      }
    };
    return Object.assign({}, state, newState);
    // ...
  });
