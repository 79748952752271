import React from "react";
import { Outer, TitleText } from "./element";

interface Props {
  fontSize?: number;
  isShowLinesOnBothSide: boolean;
  isBold?: boolean;
  gapHeight?: number;
  value: string;
}

export const Title = (props: Props) => {
  return (
    <Outer
      isShowLinesOnBothSide={props.isShowLinesOnBothSide}
      gapHeight={props.gapHeight}
    >
      <TitleText fontSize={props.fontSize} isBold={props.isBold}>
        {props.value}
      </TitleText>
    </Outer>
  );
};
