import React, { ReactElement } from "react";
import { NavigationItem } from "../../../domain/NavigationItems";
import { StyledA, StyledLi, StyledLink, StyledUl } from "./element";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  items: NavigationItem[];
  isActive?: boolean;
}

export const VerticalTextNavigation = (props: Props) => {
  const NavigationItems = props.items.map((item, index) => {
    // facebookとinstagramの時はアイコンで表示する
    let title: ReactElement<any>;
    switch (item.title.toLowerCase()) {
      case "instagram":
        title = (
          <StyledA href={item.slug}>
            {item.title + " "}
            <FontAwesomeIcon icon={["fab", "instagram"]} />
          </StyledA>
        );
        break;

      case "facebook":
        title = (
          <StyledA href={item.slug}>
            {item.title + " "}
            <FontAwesomeIcon icon={["fab", "facebook"]} />
          </StyledA>
        );
        break;

      default:
        title = <StyledLink to={item.slug}>{item.title}</StyledLink>;
    }
    return <StyledLi key={index}>{title}</StyledLi>;
  });
  return (
    <StyledUl isActive={props.isActive} itemCount={props.items.length}>
      {NavigationItems}
    </StyledUl>
  );
};
