import styled from "styled-components";

interface Props {
  fontSize?: number;
  isBold?: boolean;
  gapHeight?: number;
  subTitleAlign?: string;
}
export const SubTitle = styled.h3`
  font-size: ${(props: Props) => (props.fontSize ? props.fontSize : 20)}px;
  font-family: "M PLUS Rounded 1c";
  font-weight: ${(props: Props) => (props.isBold ? 800 : 300)};
  margin-top: 0px;
  margin-bottom: ${(props: Props) => (props.gapHeight ? props.gapHeight : 0)}px;
  text-align: ${(props: Props) =>
    props.subTitleAlign ? props.subTitleAlign : "center"};
`;
