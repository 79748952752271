import { unstable_useMediaQuery as useMediaQuery } from "@material-ui/core/useMediaQuery";
import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Action } from "typescript-fsa";
import {
  asyncActionsFetchContentOfAboutTeaKokiri,
  asyncActionsFetchImagesForSlider,
  asyncActionsFetchLineUpCategories,
  asyncActionsFetchLineUpItems,
  asyncActionsFetchSnsLinks,
  asyncActionsFetchTextForLeadSentence,
  ContentOfAboutTeaKokiriResponse,
  ImagesForSliderResponse,
  LineUpCategoriesResponse,
  LineUpItemsResponse,
  SnsLinksResponse,
  TextForLeadSentenceResponse
} from "../actions/topPageActions";
import { WindowSizeType } from "../App";
import { TeaLeavesOnMatImage } from "../presentation/atoms/Image";
import { Title } from "../presentation/atoms/title";
import { ContentOnImage } from "../presentation/molecules/contentsOnImage";
import { ParagraphWithCenteredElements } from "../presentation/molecules/paragraphWithCenteredElements";
import { ItemInformationsWithSideBar } from "../presentation/organisms/itemInformationsWithSideBar";
import { SnsLinksBox } from "../presentation/organisms/snsLinksBox";
import { TopPageImageSlider } from "../presentation/organisms/TopPageImageSlider";
import { TopPageState } from "../states/topPageState";
import { AppState } from "../store";

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): DispatcherProps {
  return {
    getImagesForSlider: () => {
      // const currentState = store.getState()
      dispatch(asyncActionsFetchImagesForSlider.started({ params: {} }));

      fetch("https://wp.kokiri.jp/wp-json/wp/v2/top_images")
        .then(response => response.json())
        .then((json: ImagesForSliderResponse) => {
          // <- Biz Logic (Async)
          dispatch(
            asyncActionsFetchImagesForSlider.done({ params: {}, result: json })
          ); // <- App Logic (Sync)
        })
        .catch((error: TypeError) => {
          dispatch(
            asyncActionsFetchImagesForSlider.failed({
              params: {},
              error
            })
          );
        });
    },
    getTextForLeadSentence: () => {
      // const currentState = store.getState()
      dispatch(asyncActionsFetchTextForLeadSentence.started({ params: {} }));

      fetch("https://wp.kokiri.jp/wp-json/wp/v2/pages/121")
        .then(response => response.json())
        .then((json: TextForLeadSentenceResponse) => {
          // <- Biz Logic (Async)
          dispatch(
            asyncActionsFetchTextForLeadSentence.done({
              params: {},
              result: json
            })
          ); // <- App Logic (Sync)
        })
        .catch((error: TypeError) => {
          dispatch(
            asyncActionsFetchTextForLeadSentence.failed({
              params: {},
              error
            })
          );
        });
    },
    getLineUpCategories: () => {
      dispatch(asyncActionsFetchLineUpCategories.started({ params: {} }));
      fetch("https://wp.kokiri.jp/wp-json/wp/v2/product_category")
        .then(response => response.json())
        .then((json: LineUpCategoriesResponse) => {
          // <- Biz Logic (Async)
          dispatch(
            asyncActionsFetchLineUpCategories.done({
              params: {},
              result: json
            })
          ); // <- App Logic (Sync)
        })
        .catch((error: TypeError) => {
          dispatch(
            asyncActionsFetchLineUpCategories.failed({
              params: {},
              error
            })
          );
        });
    },
    getLineUpItems: () => {
      dispatch(asyncActionsFetchLineUpItems.started({ params: {} }));
      fetch("https://wp.kokiri.jp/wp-json/wp/v2/line_up")
        .then(response => response.json())
        .then((json: LineUpItemsResponse) => {
          // <- Biz Logic (Async)
          dispatch(
            asyncActionsFetchLineUpItems.done({
              params: {},
              result: json
            })
          ); // <- App Logic (Sync)
        })
        .catch((error: TypeError) => {
          dispatch(
            asyncActionsFetchLineUpItems.failed({
              params: {},
              error
            })
          );
        });
    },
    getContentOfAboutTeaKokiri: () => {
      // const currentState = store.getState()
      dispatch(
        asyncActionsFetchContentOfAboutTeaKokiri.started({ params: {} })
      );

      fetch("https://wp.kokiri.jp/wp-json/wp/v2/pages/134")
        .then(response => response.json())
        .then((json: ContentOfAboutTeaKokiriResponse) => {
          // <- Biz Logic (Async)
          dispatch(
            asyncActionsFetchContentOfAboutTeaKokiri.done({
              params: {},
              result: json
            })
          ); // <- App Logic (Sync)
        })
        .catch((error: TypeError) => {
          dispatch(
            asyncActionsFetchContentOfAboutTeaKokiri.failed({
              params: {},
              error
            })
          );
        });
    },
    getSnsLinks: () => {
      // const currentState = store.getState()
      dispatch(asyncActionsFetchSnsLinks.started({ params: {} }));

      fetch("https://wp.kokiri.jp/wp-json/wp/v2/sns_links")
        .then(response => response.json())
        .then((json: SnsLinksResponse) => {
          // <- Biz Logic (Async)
          dispatch(
            asyncActionsFetchSnsLinks.done({
              params: {},
              result: json
            })
          ); // <- App Logic (Sync)
        })
        .catch((error: TypeError) => {
          dispatch(
            asyncActionsFetchSnsLinks.failed({
              params: {},
              error
            })
          );
        });
    }
  };
}

function mapStateToProps(appState: AppState): StateProps {
  return {
    topPageState: appState.topPage
  };
}

export interface StateProps {
  topPageState: TopPageState;
}

interface DispatcherProps {
  getImagesForSlider: () => void;
  getTextForLeadSentence: () => void;
  getLineUpCategories: () => void;
  getLineUpItems: () => void;
  getContentOfAboutTeaKokiri: () => void;
  getSnsLinks: () => void;
}

interface Props {
  fontSizeOfSideBar: number;
  fontSizeOfItemName: number;
  fontSizeOfItemPrice: number;
  fontSizeOfItemDescription: number;
  sideBarWidthRate: number;
  itemsCol: number;
  imageSliderSetting: any;
  hasMainImage: boolean;
  windowSize: WindowSizeType;
}

interface State {
  activeItemNum: null | number;
}

export class TopPageContainer extends React.Component<
  StateProps & DispatcherProps & Props,
  State
> {
  constructor(props: StateProps & DispatcherProps & Props) {
    super(props);
    this.state = {
      activeItemNum: null
    };
  }
  componentDidMount(): void {
    this.props.getImagesForSlider();
    this.props.getTextForLeadSentence();
    this.props.getLineUpCategories();
    this.props.getLineUpItems();
    this.props.getContentOfAboutTeaKokiri();
    this.props.getSnsLinks();
  }

  handleClickCategoryItem = (itemNum: number): void => {
    this.setState({ activeItemNum: itemNum });
  };

  render() {
    return (
      <>
        {this.props.topPageState.imagesForSlider.isFetched && (
          <TopPageImageSlider
            images={this.props.topPageState.imagesForSlider.imageUrls}
            setting={this.props.imageSliderSetting}
          />
        )}
        {this.props.hasMainImage && (
          <ContentOnImage
            text={this.props.topPageState.textForLeadSentence}
            fontSize={20}
          />
        )}
        <Title
          value={"商品一覧"}
          fontSize={26}
          isBold={false}
          isShowLinesOnBothSide={true}
        />
        <ItemInformationsWithSideBar
          sidebarItems={this.props.topPageState.lineUpCategories}
          mainItems={this.props.topPageState.lineUpItems}
          activeItemNum={
            this.state.activeItemNum ? this.state.activeItemNum : 2
          }
          fontSizeOfSideBar={this.props.fontSizeOfSideBar}
          fontSizeOfItemName={this.props.fontSizeOfItemName}
          fontSizeOfItemPrice={this.props.fontSizeOfItemPrice}
          fontSizeOfItemDescription={this.props.fontSizeOfItemDescription}
          col={this.props.itemsCol}
          sideBarWidthRate={this.props.sideBarWidthRate}
          handleClickSideBarItem={this.handleClickCategoryItem}
          windowSize={this.props.windowSize}
        />
        {/*<ParagraphWithCenteredElements*/}
        {/*  title={this.props.topPageState.contentOfAboutTeaKokiri.title}*/}
        {/*  titleFontSize={28}*/}
        {/*  subTitle={this.props.topPageState.contentOfAboutTeaKokiri.subTitle}*/}
        {/*  subTitleFontSize={22}*/}
        {/*  body={this.props.topPageState.contentOfAboutTeaKokiri.body}*/}
        {/*  innerWidth={"80%"}*/}
        {/*  gapHeight={10}*/}
        {/*/>*/}
        {/*<TeaLeavesOnMatImage width={"100%"} />*/}
        {/*<SnsLinksBox items={this.props.topPageState.snsLinks} />*/}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopPageContainer);
