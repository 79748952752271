import React, { Component } from "react";
import { default as Slider } from "react-slick";
import "slick-carousel/slick/slick.css";

import {
  Button,
  Buttons,
  ButtonsOuter,
  Img,
  ImgOuter,
  SlideOuter
} from "./element";

export interface Props {
  images: string[];
  setting?: any;
}

export class ImageSlider extends Component<Props> {
  private slider!: Slider;

  render() {
    const settings = this.props.setting
      ? this.props.setting
      : {
          dots: false,
          infinite: true,
          arrows: false,
          // speed: 200,
          fade: true,
          touchThreshold: 100,
          centerMode: true,
          centerPadding: "30%",
          autoplay: true,
          autoplaySpeed: 3000
        };

    return (
      <div>
        <SlideOuter>
          <Slider
            ref={(component: Slider) => (this.slider = component)}
            {...settings}
          >
            {this.props.images.map((content, index) => {
              return (
                <ImgOuter key={index}>
                  <Img src={content} />
                </ImgOuter>
              );
            })}
          </Slider>
        </SlideOuter>
        <ButtonsOuter>
          <Buttons>
            {this.props.images.map((_, index: number) => {
              return (
                <Button
                  key={index}
                  onClick={() => this.slider.slickGoTo(index)}
                />
              );
            })}
          </Buttons>
        </ButtonsOuter>
      </div>
    );
  }
}
