import { reducerWithInitialState } from "typescript-fsa-reducers";
import { gameActions, gameAsyncActions } from "../actions/gameActions";

const ROW_NUM: number = 3;
const COL_NUM: number = 3;

export const TableParams = {
  rowNum: ROW_NUM,
  colNum: COL_NUM
};

export interface GameState {
  history: Array<{ squares: Array<string | null> }>;
  stepNumber: number;
  xIsNext: boolean;
  winner: string | null;
}

const initialState: GameState = {
  history: [
    {
      squares: Array(ROW_NUM * COL_NUM).fill(null)
    }
  ],
  stepNumber: 0,
  xIsNext: true,
  winner: null
};

export const gameReducer = reducerWithInitialState(initialState)
  .case(gameActions.updateGameState, (state, squaresNo: number) => {
    const history = state.history;
    const squares = history[history.length - 1].squares.slice();
    squares[squaresNo] = state.xIsNext ? "X" : "O";
    const newState = {
      history: history.concat([{ squares }]),
      stepNumber: history.length,
      xIsNext: !state.xIsNext,
      winner: calculateWinner(squares)
    };
    return Object.assign({}, state, newState);
  })
  .case(gameAsyncActions.started, state => {
    return Object.assign({}, state);
  })
  .case(gameAsyncActions.failed, state => {
    // ...
    return Object.assign({}, state);
  })
  .case(gameAsyncActions.done, (state, { result }) => {
    return Object.assign({}, state, { history: result });
    // ...
  });

const calculateWinner = (squares: Array<string | null>): string | null => {
  const lines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6]
  ];

  const status = lines.reduce((test: string | null, line: number[]):
    | string
    | null => {
    const [a, b, c] = line;
    const test1 =
      squares[a] && squares[a] === squares[b] && squares[a] === squares[c]
        ? squares[a]
        : null;
    return !!test ? test : test1;
  }, null);
  return status;
};
