import styled from "styled-components";

export const FooterOuter = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #d1bada;
  height: 170px;
  width: 100%;
`;

export const FooterInner = styled.div``;

export const FlexOuter = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const FlexInner = styled.div`
  padding: 0;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SiteNameOuter = styled.div`
  width: 50%;
  text-align: left;
`;

export const NavigationOuter = styled.div`
  width: 50%;
  right: 0px;
  height: auto;
  bottom: 0px;
  padding: 0;
  text-align: left;
`;
