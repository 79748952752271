import React from "react";

import { Title } from "../../atoms/title";

interface Props {
  termsOfService: {
    title: string;
    body: string;
  };
}

export const TermsOfService = (props: Props) => {
  return (
    <>
      <Title isShowLinesOnBothSide={true} value={"利用規約"} fontSize={28} />
      <span dangerouslySetInnerHTML={{ __html: props.termsOfService.body }} />
    </>
  );
};
