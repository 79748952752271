import { reducerWithInitialState } from "typescript-fsa-reducers";
import { asyncActionsFetchEvent } from "../actions/salesActivityPageActions";

export interface Time {
  day: string;
  time: string;
}

export interface Event {
  eventName: string;
  venue: {
    name: string;
    latLng: {
      lat: string;
      lng: string;
    };
  };
  address: string;
  imageUrl: string;
  description: string;
  times: Time[];
}

export interface SalesActivityPageState {
  events: Event[];
}

const initialState: SalesActivityPageState = {
  events: []
};

const getInitialState = () => ({
  eventName: "",
  venue: {
    name: "",
    latLng: {
      lat: "",
      lng: ""
    }
  },
  address: "",
  description: "",
  imageUrl: "",
  times: []
});

export const salesActivityPageReducer = reducerWithInitialState(initialState)
  /**
   * イベントの取得
   */
  .case(asyncActionsFetchEvent.started, state => {
    return Object.assign({}, state);
  })
  .case(asyncActionsFetchEvent.failed, state => {
    // ...
    return Object.assign({}, state);
  })
  // tslint:disable-next-line:no-shadowed-variable
  .case(asyncActionsFetchEvent.done, (state, { result }) => {
    const events = result.map(r => {
      const event = {
        ...getInitialState(),
        eventName: r.title.rendered,
        imageUrl: r.acf.image,
        venue: {
          name: r.acf.venue_name,
          latLng: {
            lat: (r.acf.map && r.acf.map.lat) || "",
            lng: (r.acf.map && r.acf.map.lng) || ""
          }
        },
        times: [
          { day: r.acf.day_1, time: r.acf.time_1 || "" },
          { day: r.acf.day_2 || "", time: r.acf.time_2 || "" },
          { day: r.acf.day_3 || "", time: r.acf.time_3 || "" },
          { day: r.acf.day_4 || "", time: r.acf.time_4 || "" },
          { day: r.acf.day_5 || "", time: r.acf.time_5 || "" },
          { day: r.acf.day_6 || "", time: r.acf.time_6 || "" },
          { day: r.acf.day_7 || "", time: r.acf.time_7 || "" }
        ]
      };
      return event;
    });
    return Object.assign({}, state, { events });
    // ...
  });
