import React from "react";

import { unstable_useMediaQuery as useMediaQuery } from "@material-ui/core/useMediaQuery";
import { Text } from "../../atoms/text";
import { FlexInner, FlexOuter } from "./element";

interface StoreInformationProps {
  name: string;
  address: string;
  tel: string;
  url: string;
}

interface Props {
  storeInformation: StoreInformationProps;
}

export const StoreInformation = (props: Props) => {
  const isTabletOrMobile = useMediaQuery("(max-width:768px)");
  const isTabletOrMobileDevice = useMediaQuery("( max-device-width: 768px)");
  const isVertical = isTabletOrMobile || isTabletOrMobileDevice;

  return (
    <>
      <FlexOuter isVertical={isVertical}>
        <FlexInner isVertical={isVertical}>
          <iframe
            src={props.storeInformation.url}
            width="100%"
            height="200"
            frameBorder="0"
            allowFullScreen={true}
          />
        </FlexInner>
        <FlexInner isVertical={isVertical}>
          <Text fontSize={26} isBold={true}>
            {props.storeInformation.name}
          </Text>
          <Text fontSize={24}>{props.storeInformation.address}</Text>
          <Text fontSize={24}>{props.storeInformation.tel}</Text>
        </FlexInner>
      </FlexOuter>
    </>
  );
};
