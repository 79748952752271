import { reducerWithInitialState } from "typescript-fsa-reducers";
import { asyncActionsFetchShippingCost } from "../actions/shippingCostPageActions";

export interface ShippingCostPageState {
  shippingCost: Array<{ title: string; body: string }>;
}

const initialState: ShippingCostPageState = {
  shippingCost: []
};

export const shippingCostPageReducer = reducerWithInitialState(initialState)
  /**
   * 店舗情報のURLの取得
   */
  .case(asyncActionsFetchShippingCost.started, state => {
    return Object.assign({}, state);
  })
  .case(asyncActionsFetchShippingCost.failed, state => {
    // ...
    return Object.assign({}, state);
  })
  .case(asyncActionsFetchShippingCost.done, (state, { result }) => {
    const convertedResult = result.map(data => {
      return {
        title: data.title.rendered,
        body: data.acf.body
      };
    });
    return Object.assign({}, state, { shippingCost: convertedResult });
    // ...
  });
