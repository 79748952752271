import styled from "styled-components";

interface Props {
  fontSize?: number;
  isBold?: boolean;
  isShowLinesOnBothSide?: boolean;
  gapHeight?: number;
}

export const Outer = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  margin-bottom: ${props => (props.gapHeight ? props.gapHeight : 0)}px;

  ${(props: Props) =>
    props.isShowLinesOnBothSide
      ? `
  &:before {
  content: "";
  flex-grow: 1;
  height: 1px;
  background: #444;
  display: block;
  margin-right: 0.4em;
  }
  &:after {
  content: "";
  flex-grow: 1;
  height: 1px;
  background: #444;
  display: block;
  margin-left: 0.4em;
  }`
      : null}
`;

export const TitleText = styled.h2`
  font-size: ${(props: Props) => (props.fontSize ? props.fontSize : 17)}px;
  font-family: "M PLUS Rounded 1c";
  font-weight: ${(props: Props) => (props.isBold ? 800 : 300)};
`;
