import styled from "styled-components";
export const OuterForSideBar = styled.div`
  width: calc(100% - 14px);
  font-weight: 300;
  list-style: none;
  border: solid 2px #d1bada;
  display: flex;
  align-items: center;
  padding: 5px 5px 5px 5px;
  cursor: pointer;
  margin-bottom: -2px;
`;

export const OuterForSnsBox = styled.a`
  width: calc(100% - 14px);
  font-weight: 300;
  list-style: none;
  border: solid 2px #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 5px 15px 5px;
  cursor: pointer;
  margin-bottom: -2px;
`;

export const OuterAlignRight = styled.div`
  width: 100%;
  font-weight: 300;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`;

export const OuterAlignLeft = styled.div`
  width: 100%;
  font-weight: 300;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;
export const OuterAlignCenter = styled.div`
  width: 100%;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
