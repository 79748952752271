import React from "react";
import { InputText } from "../../atoms/inputText";
import { InputTextArea } from "../../atoms/inputTextArea";
import { Text } from "../../atoms/text";
import { Outer } from "./elemnt";

interface Props {
  name: string;
  value: string;
  onChangeText: (e: any) => void;
}

export const TextFormContentBox = (props: Props) => {
  return (
    <Outer>
      <label>
        <Text fontSize={24} isBold={false}>
          {props.name}
        </Text>
      </label>
      <InputText value={props.value} onChange={e => props.onChangeText(e)} />
    </Outer>
  );
};

export const TextAreaFormContentBox = (props: Props) => {
  return (
    <Outer>
      <label>
        <Text fontSize={24} isBold={false}>
          {props.name}
        </Text>
      </label>
      <InputTextArea
        value={props.value}
        rows={10}
        onChange={e => props.onChangeText(e)}
      />
    </Outer>
  );
};
