import React from "react";

import { Event } from "../../../states/salesActivityPageState";
import { Title } from "../../atoms/title";
import { EventCard } from "../../molecules/eventCard";

interface Props {
  Events: Event[];
}

export const SalesActivity = (props: Props) => {
  return (
    <>
      <Title isShowLinesOnBothSide={true} value={"出店情報"} fontSize={28} />
      {props.Events.map((event: Event, index: number) => {
        return <EventCard key={index} event={event} />;
        /*<StoreInformation key={index} storeInformation={storeInformation} />*/
      })}
    </>
  );
};
