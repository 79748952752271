import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface Props {
  color?: string;
}

export const StyledUl = styled.ul`
  display: inline-flex;
  padding: 0;
  margin: 0;
`;

export const StyledLi = styled.li`
  display: flex;
  text-align: center;
  border-radius: 5px;
  &:hover {
    background-color: #d1bada;
    color: #ffffff;
  }
`;

export const StyledLink = styled(Link)`
  font-size: 16px;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 300;
  padding: 0 10px;
  text-decoration: none;
  color: ${(props: Props) => `${props.color ? props.color : "#d1bada"}`};
  &:hover {
    color: #ffffff;
  }
`;

export const StyledA = styled.a`
  font-size: 16px;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 300;
  padding: 0 10px;
  text-decoration: none;
  color: ${(props: Props) => `${props.color ? props.color : "#d1bada"}`};
  &:hover {
    color: #ffffff;
  }
`;

// .demo1{
//   position: relative;
//   overflow: hidden;
//   width: 180px;
//
//   line-height: 0;
//   text-align: center;
// }
// .demo1 div{
//   position: absolute;
//   width: 100%;
//   height: 100%;
//
//   top: 0;
//   left: 0;
//
//   background: rgba(0,0,0,0.6);
//   transition: 0.3s;
//
//   cursor: pointer;
// }
// .demo1 h1{
//   font-size: 16px;
//   padding: 0 5px;
//   margin: 0;
//
//   line-height: 1.5;
//
//   position: relative;
//   top: 50%;
//   -webkit-transform: translateY(-50%);
//   -ms-transform: translateY(-50%);
//   transform: translateY(-50%);
// }
// .demo1 h1 a{
//   color: #fff;
//   text-decoration: none;
// }
// .demo1 img{
//   border: none;
// }
// .demo1:hover div{
//   top: -100%;
// }
