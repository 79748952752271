import actionCreatorFactory from "typescript-fsa";

const actionCreator = actionCreatorFactory();

type Params = object;

export type StoreInformationsResponse = Array<{
  title: { rendered: string };
  acf: { address: string; tel: string; url: string };
}>;

export const asyncActionsFetchStoreInformations = actionCreator.async<
  Params,
  StoreInformationsResponse,
  Error
>("ACTIONS_FETCH_STORE_INFORMATIONS");
