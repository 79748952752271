import React, { ReactElement } from "react";
import { FlexInner, FlexOuter } from "./element";

interface Props {
  renderStoreInformation: () => ReactElement<any>;
}

export const DesktopMiddleDefaultTemplate = (props: Props) => {
  return (
    <FlexOuter>
      <FlexInner>{props.renderStoreInformation()}</FlexInner>
    </FlexOuter>
  );
};
