import { combineReducers, createStore } from "redux";
import { aboutPageReducer, AboutPageState } from "./states/aboutPageState";
import {
  contactPageReducer,
  ContactPageState
} from "./states/contactPageState.ts";
import { footerReducer, FooterState } from "./states/footerState";
import { gameReducer, GameState } from "./states/gameState";
import { headerReducer, HeaderState } from "./states/headerState";
import {
  privacyPolicyPageReducer,
  PrivacyPolicyPageState
} from "./states/privacyPolicyPageState";
import {
  salesActivityPageReducer,
  SalesActivityPageState
} from "./states/salesActivityPageState";
import {
  shippingCostPageReducer,
  ShippingCostPageState
} from "./states/shippingCostPageState";
import {
  storeInformationPageReducer,
  StoreInformationPageState
} from "./states/storeInformationPageState";
import {
  termsOfServicePageReducer,
  TermsOfServicePageState
} from "./states/termsOfServicePageState";
import { topPageReducer, TopPageState } from "./states/topPageState";

export interface AppState {
  game: GameState;
  header: HeaderState;
  footer: FooterState;
  topPage: TopPageState;
  storeInformationPage: StoreInformationPageState;
  shippingCostPage: ShippingCostPageState;
  termsOfServicePage: TermsOfServicePageState;
  privacyPolicyPage: PrivacyPolicyPageState;
  contactPage: ContactPageState;
  salesActivityPage: SalesActivityPageState;
  aboutPage: AboutPageState;
}

const store = createStore(
  combineReducers<AppState>({
    game: gameReducer,
    header: headerReducer,
    footer: footerReducer,
    topPage: topPageReducer,
    storeInformationPage: storeInformationPageReducer,
    shippingCostPage: shippingCostPageReducer,
    termsOfServicePage: termsOfServicePageReducer,
    privacyPolicyPage: privacyPolicyPageReducer,
    contactPage: contactPageReducer,
    salesActivityPage: salesActivityPageReducer,
    aboutPage: aboutPageReducer
  })
);

export default store;
