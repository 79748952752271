import styled from "styled-components";

interface Props {
  isVertical: boolean;
}

export const FlexOuter = styled.div`
  display: ${(props: Props) => (props.isVertical ? "box" : "flex")};
  justify-content: space-around;
  background-color: #fefcf0;
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 26px;
`;

export const FlexInnerLeft = styled.div`
  width: ${(props: Props) => (props.isVertical ? "90%" : "35%")};
  margin: 0 auto;
`;

export const FlexInnerRight = styled.div`
  width: ${(props: Props) => (props.isVertical ? "90%" : "50%")};
  margin: 0 auto;
`;

export const Img = styled.img`
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
`;
