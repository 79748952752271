import actionCreatorFactory from "typescript-fsa";

const actionCreator = actionCreatorFactory();

type Params = object;
interface FooterNavigationMenuResponse {
  items: Array<{ id: number; title: string; url: string }>;
}

export const asyncActionsFetchFooterNavigationMenu = actionCreator.async<
  Params,
  FooterNavigationMenuResponse,
  Error
>("ACTIONS_FETCH_FOOTER_NAVIGATION_MENU");

export type SnsLinksResponse = Array<{
  title: { rendered: string };
  acf: { url: string };
}>;
export const asyncActionsFetchSnsLinks = actionCreator.async<
  Params,
  SnsLinksResponse,
  Error
>("ACTIONS_FETCH_SNS_LINKS");
