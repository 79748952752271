import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Action } from "typescript-fsa";
import {
  asyncActionsFetchStoreInformations,
  StoreInformationsResponse
} from "../actions/storeInformationPageActions";
import { StoreInformations } from "../presentation/organisms/storeInformations";
import { StoreInformationPageState } from "../states/storeInformationPageState";
import { AppState } from "../store";

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): DispatcherProps {
  return {
    getStoreInformations: () => {
      dispatch(asyncActionsFetchStoreInformations.started({ params: {} }));

      fetch("https://wp.kokiri.jp/wp-json/wp/v2/store_information")
        .then(response => response.json())
        .then((json: StoreInformationsResponse) => {
          // <- Biz Logic (Async)
          dispatch(
            asyncActionsFetchStoreInformations.done({
              params: {},
              result: json
            })
          ); // <- App Logic (Sync)
        })
        .catch((error: TypeError) => {
          dispatch(
            asyncActionsFetchStoreInformations.failed({
              params: {},
              error
            })
          );
        });
    }
  };
}

function mapStateToProps(appState: AppState): StateProps {
  return {
    storeInformationPageState: appState.storeInformationPage
  };
}

export interface StateProps {
  storeInformationPageState: StoreInformationPageState;
}

interface DispatcherProps {
  getStoreInformations: () => void;
}

interface State {
  activeItemNum: null | number;
}

export class StoreInformationPageContainer extends React.Component<
  StateProps & DispatcherProps,
  State
> {
  constructor(props: StateProps & DispatcherProps) {
    super(props);
    this.state = {
      activeItemNum: null
    };
  }
  componentDidMount(): void {
    this.props.getStoreInformations();
  }
  render() {
    return (
      <StoreInformations
        storeInformations={
          this.props.storeInformationPageState.storeInformations
        }
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreInformationPageContainer);
