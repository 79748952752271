import React from "react";
import MediaQuery from "react-responsive";
import PrivacyPolicyPageContainer from "../../containers/privacyPolicyPageContainer";

import { DesktopFullDefaultTemplate } from "../templates/defaultPageTemplates/desktopFullDefaultPageTemplate";
import { DesktopMiddleDefaultTemplate } from "../templates/defaultPageTemplates/desktopMiddleDefaultPageTemplate";
import { DesktopSmallDefaultTemplate } from "../templates/defaultPageTemplates/desktopSmallDefaultPageTemplate";

export const PrivacyPolicyPage = () => {
  return (
    <>
      <MediaQuery minWidth={1224}>
        <DesktopFullDefaultTemplate
          renderStoreInformation={() => <PrivacyPolicyPageContainer />}
        />
      </MediaQuery>

      <MediaQuery minWidth={971} maxWidth={1223}>
        <DesktopMiddleDefaultTemplate
          renderStoreInformation={() => <PrivacyPolicyPageContainer />}
        />
      </MediaQuery>

      <MediaQuery maxWidth={970}>
        <DesktopSmallDefaultTemplate
          renderStoreInformation={() => <PrivacyPolicyPageContainer />}
        />
      </MediaQuery>
    </>
  );
};
