import { reducerWithInitialState } from "typescript-fsa-reducers";
import { asyncActionsFetchPrivacyPolicy } from "../actions/privacyPolicyPageActions";

export interface PrivacyPolicyPageState {
  privacyPolicy: {
    title: string;
    body: string;
  };
}

const initialState: PrivacyPolicyPageState = {
  privacyPolicy: {
    title: "",
    body: ""
  }
};

export const privacyPolicyPageReducer = reducerWithInitialState(initialState)
  /**
   * 利用規約のURLの取得
   */
  .case(asyncActionsFetchPrivacyPolicy.started, state => {
    return Object.assign({}, state);
  })
  .case(asyncActionsFetchPrivacyPolicy.failed, state => {
    // ...
    return Object.assign({}, state);
  })
  .case(asyncActionsFetchPrivacyPolicy.done, (state, { result }) => {
    const convertedResult = {
      title: result.title.rendered,
      body: result.content.rendered
    };
    return Object.assign({}, state, { privacyPolicy: convertedResult });
    // ...
  });
