import React from "react";
import { TextInBoxForSideBar } from "../../molecules/textInBox/index";

export interface Props {
  items: Item[];
  activeItemNum?: null | number;
  handleClickItem: (itemNum: number) => void;
  fontSize: number;
}

export interface Item {
  id?: number;
  commonKey: number;
  name: string;
  iconImageUrl?: string;
}

export const SimpleSidebar = (props: Props) => {
  return (
    <>
      {props.items.map((item, index) => {
        return (
          <TextInBoxForSideBar
            fontSize={props.fontSize}
            key={index}
            isActive={item.commonKey === props.activeItemNum ? true : false}
            iconImageUrl={item.iconImageUrl}
            text={item.name}
            handleClick={() => props.handleClickItem(item.commonKey)}
          />
        );
      })}
    </>
  );
};
