import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Action } from "typescript-fsa";
import { asyncActionsFetchNavigationMenu } from "../actions/headerActions";
import { Header } from "../presentation/organisms/header";
import { HeaderState } from "../states/headerState";
import { AppState } from "../store";

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): DispatcherProps {
  return {
    getNavigationMenu: () => {
      // const currentState = store.getState()
      dispatch(asyncActionsFetchNavigationMenu.started({ params: {} }));

      fetch("https://wp.kokiri.jp/wp-json/wp-api-menus/v2/menus/5")
        .then(response => response.json())
        .then(
          (json: {
            items: Array<{ id: number; title: string; url: string }>;
          }) => {
            // <- Biz Logic (Async)
            dispatch(
              asyncActionsFetchNavigationMenu.done({ params: {}, result: json })
            ); // <- App Logic (Sync)
          }
        )
        .catch((error: TypeError) => {
          dispatch(
            asyncActionsFetchNavigationMenu.failed({
              params: {},
              error
            })
          );
        });
    }
  };
}

function mapStateToProps(appState: AppState): StateProps {
  return {
    headerState: appState.header
  };
}

export interface StateProps {
  headerState: HeaderState;
}

interface DispatcherProps {
  getNavigationMenu: () => void;
}

export interface StateTypes {
  isActive: boolean;
}

export class HeaderContainer extends React.Component<
  StateProps & DispatcherProps,
  StateTypes
> {
  constructor(props: StateProps & DispatcherProps) {
    super(props);
    this.state = {
      isActive: false
    };
  }

  componentDidMount(): void {
    this.props.getNavigationMenu();
  }

  handleClick = (): void => {
    this.setState({ isActive: !this.state.isActive });
  };

  render() {
    return (
      <Header
        navigationItems={this.props.headerState.navigationMenu}
        isActive={this.state.isActive}
        handleClickMenuButton={this.handleClick}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderContainer);
