import React, { ReactElement } from "react";
import { Outer } from "./element";

interface Props {
  renderStoreInformation: () => ReactElement<any>;
}

export const DesktopFullDefaultTemplate = (props: Props) => {
  return <Outer>{props.renderStoreInformation()}</Outer>;
};
