import React from "react";
import MediaQuery from "react-responsive";
import { NavigationItem } from "../../../domain/NavigationItems";
import { SiteNameLogoWhite } from "../../atoms/siteNameLogo";

import { TextNavigation } from "../../atoms/textNavigation";
import { TextInBoxAlignCenter } from "../../molecules/textInBox";
import { DesktopFullFooterTemplate } from "../../templates/footerTemplates/desktopFullFooterTemplate";
import { DesktopMiddleFooterTemplate } from "../../templates/footerTemplates/desktopMiddleFooterTemplate";
import { DesktopSmallFooterTemplate } from "../../templates/footerTemplates/desktopSmallFooterTemplate";
import { StyledLink } from "./element";

interface Props {
  navigationItems: NavigationItem[];
}

export const Footer = (props: Props) => {
  return (
    <>
      <MediaQuery minWidth={1224}>
        <DesktopFullFooterTemplate
          renderSiteNameLogo={() => (
            <StyledLink to={"/"}>
              <SiteNameLogoWhite width={195} />
            </StyledLink>
          )}
          renderTextNavigation={() => (
            <TextNavigation
              items={props.navigationItems}
              color={"#ffffff"}
              hasSeparater={true}
            />
          )}
          renderCopyRight={() => (
            <TextInBoxAlignCenter
              color={"#ffffff"}
              text={"Copyright © Tea kokiri Inc. All rights reserved"}
            />
          )}
        />
      </MediaQuery>

      <MediaQuery minWidth={971} maxWidth={1223}>
        <DesktopMiddleFooterTemplate
          renderSiteNameLogo={() => (
            <StyledLink to={"/"}>
              <SiteNameLogoWhite width={195} />
            </StyledLink>
          )}
          renderTextNavigation={() => (
            <TextNavigation
              items={props.navigationItems}
              color={"#ffffff"}
              hasSeparater={true}
            />
          )}
          renderCopyRight={() => (
            <TextInBoxAlignCenter
              color={"#ffffff"}
              text={"Copyright © Tea kokiri Inc. All rights reserved"}
            />
          )}
        />
      </MediaQuery>

      <MediaQuery maxWidth={970}>
        <DesktopSmallFooterTemplate
          renderSiteNameLogo={() => (
            <StyledLink to={"/"}>
              <SiteNameLogoWhite width={195} />
            </StyledLink>
          )}
          renderTextNavigation={() => (
            <TextNavigation
              items={props.navigationItems}
              color={"#ffffff"}
              hasSeparater={true}
            />
          )}
          renderCopyRight={() => (
            <TextInBoxAlignCenter
              color={"#ffffff"}
              text={"Copyright © Tea kokiri Inc. All rights reserved"}
            />
          )}
        />
      </MediaQuery>
    </>
  );
};
