import React from "react";

import { withStyles, WithStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import { Outer } from "./element";

const styles = {
  default: {
    marginLeft: 5,
    fontSize: 23,
    transform: "rotate(0deg)",
    transition: "0.3s"
  },
  active: {
    marginLeft: 5,
    fontSize: 23,
    color: "#ffffff",
    transform: "rotate(90deg)",
    transition: "0.3s"
  }
};

export type ClassNames = keyof typeof styles;
export interface Props {
  isActive?: boolean;
  handleClick: () => void;
}

export const MenuButton = withStyles(styles)(
  (props: Props & WithStyles<ClassNames>) => {
    const classes = props.classes;
    return (
      <Outer isActive={props.isActive} onClick={() => props.handleClick()}>
        MENU
        <MenuIcon
          className={props.isActive ? classes.active : classes.default}
        />
      </Outer>
    );
  }
);
