import actionCreatorFactory from "typescript-fsa";
import { FormValue } from "../states/contactPageState.ts";

const actionCreator = actionCreatorFactory();

type Params = object;

export interface PostFormResponse {
  into: string;
  message: string;
  status: string;
}

export const asyncActionsPostForm = actionCreator.async<
  Params,
  PostFormResponse,
  Error
>("ACTIONS_POST_FORM");

export const formActions = {
  changeFormValue: actionCreator<FormValue>("CHANGE_FORM_VALUE"),
  initializeForm: actionCreator("INITIALIZE_FORM")
};
