import { unstable_useMediaQuery as useMediaQuery } from "@material-ui/core/useMediaQuery";
import React from "react";
import { ScatteredHerbsImage, TeacupImage } from "../../atoms/Image/";
import { Text } from "../../atoms/text/";
import {
  BackgroundImageOuter,
  FrontImageOuter,
  Outer,
  TextOuter
} from "./element";

interface Props {
  text: string;
  fontSize?: number;
}

export const ContentOnImage = (props: Props) => {
  const isTabletOrMobile = useMediaQuery("(max-width:768px)");
  const isTabletOrMobileDevice = useMediaQuery("( max-device-width: 768px)");
  const fontsize =
    isTabletOrMobile || isTabletOrMobileDevice ? 12 : props.fontSize;
  return (
    <>
      <Outer>
        <BackgroundImageOuter>
          <ScatteredHerbsImage />
        </BackgroundImageOuter>
        <FrontImageOuter>
          <TeacupImage width={"25%"} />
        </FrontImageOuter>
        <TextOuter>
          {props.text
            .replace(/(<br>|<br \/>)/gi, "\n")
            .replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, "")
            .split("\n")
            .filter(str => str.length !== 0)
            .map((str, i) => {
              return (
                <Text fontSize={fontsize} key={i}>
                  {str}
                </Text>
              );
            })}
        </TextOuter>
      </Outer>
    </>
  );
};
