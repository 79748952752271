import React, { ReactElement } from "react";
import {
  HeaderInner,
  HeaderOuter,
  NavigationOuter,
  SiteNameOuter
} from "./element";

interface Props {
  renderSiteNameLogo: () => ReactElement<any>;
  renderMenuButton: () => ReactElement<any>;
  renderTextNavigation: () => ReactElement<any>;
  renderVerticalTextNavigation: () => ReactElement<any>;
}

export const DesktopSmallHeaderTemplate = (props: Props) => {
  return (
    <>
      <HeaderOuter>
        <HeaderInner>
          <SiteNameOuter>{props.renderSiteNameLogo()}</SiteNameOuter>
          <NavigationOuter>{props.renderMenuButton()}</NavigationOuter>
        </HeaderInner>
      </HeaderOuter>
      <HeaderOuter>
        <HeaderInner>{props.renderVerticalTextNavigation()}</HeaderInner>
      </HeaderOuter>
    </>
  );
};
