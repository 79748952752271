import React from "react";

import Button from "@material-ui/core/Button";
import {
  MuiThemeProvider,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

import { MyTheme } from "../../../myTheme";
import { FormState, FormValue } from "../../../states/contactPageState.ts";
import { Title } from "../../atoms/title";
import {
  TextAreaFormContentBox,
  TextFormContentBox
} from "../../molecules/formContentBox";
import {
  FormContentsOuter,
  FormOuter,
  SendButtonOuter,
  SendSuccessOuter
} from "./element";

interface Props {
  formValue: FormValue;
  formState: FormState;
  handleChangeNameForm: (e: any) => void;
  handleChangeEmailForm: (e: any) => void;
  handleChangeSubjectForm: (e: any) => void;
  handleChangeMessageForm: (e: any) => void;
  handleClickSendButton: () => void;
}

const styles = (): StyleRules => ({
  button: {
    margin: "auto",
    textAlign: "center"
  }
});

export const ContactForm = withStyles(styles)(
  (props: Props & WithStyles<typeof styles>) => {
    const { classes } = props;
    return (
      <>
        <Title
          isShowLinesOnBothSide={true}
          value={"お問い合わせ"}
          fontSize={28}
        />
        {props.formState.isSendSuccess ? (
          <p>お問い合わせありがとうございます。内容を送信致しました。</p>
        ) : (
          <FormOuter>
            <form>
              <FormContentsOuter>
                <TextFormContentBox
                  name={"お名前"}
                  value={props.formValue.name}
                  onChangeText={props.handleChangeNameForm}
                />
                <TextFormContentBox
                  name={"メールアドレス"}
                  value={props.formValue.email}
                  onChangeText={props.handleChangeEmailForm}
                />
                <TextFormContentBox
                  name={"件名"}
                  value={props.formValue.subject}
                  onChangeText={props.handleChangeSubjectForm}
                />
                <TextAreaFormContentBox
                  name={"本文"}
                  value={props.formValue.message}
                  onChangeText={props.handleChangeMessageForm}
                />
              </FormContentsOuter>
              <SendButtonOuter>
                {props.formState.isFetchSuccess && (
                  <p>入力内容をご確認のうえ、再度送信をお願い致します。</p>
                )}
                <MuiThemeProvider theme={MyTheme}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    onClick={props.handleClickSendButton}
                  >
                    送信
                  </Button>
                </MuiThemeProvider>
              </SendButtonOuter>
            </form>
          </FormOuter>
        )}
      </>
    );
  }
);
