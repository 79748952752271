import React from "react";

import { SubTitle } from "../../atoms/subTitle";
import { Text } from "../../atoms/text";
import { Title } from "../../atoms/title/";
// import { SubTitle } from "../../atoms/SubTitle/";
import { FlexInner, FlexOuter } from "./element";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Sentence } from "../../atoms/sentence";

library.add(fab); // あらかじめ使用するアイコンを追加しておく

interface Props {
  innerWidth?: string;
  gapHeight?: number;
  title: string;
  titleFontSize?: number;
  subTitle?: string;
  subTitleFontSize?: number;
  body: string;
  subTitleAlign?: string;
}

export const ParagraphWithCenteredElements = (props: Props) => {
  return (
    <FlexOuter>
      <FlexInner innerWidth={props.innerWidth}>
        <Title
          gapHeight={props.gapHeight}
          isBold={true}
          value={props.title}
          isShowLinesOnBothSide={false}
          fontSize={props.titleFontSize}
        />
        {props.subTitle && !!props.subTitle.length && (
          <SubTitle
            gapHeight={props.gapHeight}
            isBold={false}
            fontSize={props.subTitleFontSize}
            subTitleAlign={props.subTitleAlign}
          >
            {props.subTitle.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, "")}
          </SubTitle>
        )}
        {props.body
          .replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, "")
          .split("\n")
          .filter(str => str.length !== 0)
          .map((str, i) => {
            return <Sentence key={i}>{str}</Sentence>;
          })}
      </FlexInner>
    </FlexOuter>
  );
};
