import React from "react";

import { Title } from "../../atoms/title";
import { ArticleCard } from "../../molecules/articleCard";

interface Props {
  shippingCost: Array<{ title: string; body: string }>;
}

export const ShippingCost = (props: Props) => {
  return (
    <>
      <Title
        isShowLinesOnBothSide={true}
        value={"送料について"}
        fontSize={28}
      />
      {props.shippingCost.map(
        (shippingCostChunk: { title: string; body: string }, index: number) => {
          return (
            <ArticleCard
              key={index}
              title={shippingCostChunk.title}
              body={shippingCostChunk.body}
            />
          );
        }
      )}
    </>
  );
};
