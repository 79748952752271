import styled from "styled-components";

export const InputTextArea = styled.textarea`
  width: calc(100% - 1em);
  padding: 0.5em;
  font-size: 17px;
  color: black;
  background: #f8e6ff;
  border: #c798d9 solid 1px;
  border-radius: 3px;
`;
