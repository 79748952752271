import styled from "styled-components";

export const FooterOuter = styled.div`
  display: inline-flex;
  align-items: center;
  text-align: center;
  background-color: #d1bada;
  height: 170px;
`;

export const FooterInner = styled.div``;

export const FlexOuter = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const SiteNameOuter = styled.div`
  width: 90%;
  text-align: center;
`;

export const NavigationOuter = styled.div`
  margin: 30px auto;
  width: 90%;
  text-align: center;
`;
