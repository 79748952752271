import React from "react";
import MediaQuery from "react-responsive";
import AboutPageContainer from "../../containers/aboutPageContainer";
import { DesktopFullDefaultTemplate } from "../templates/defaultPageTemplates/desktopFullDefaultPageTemplate";
import { DesktopMiddleDefaultTemplate } from "../templates/defaultPageTemplates/desktopMiddleDefaultPageTemplate";
import { DesktopSmallDefaultTemplate } from "../templates/defaultPageTemplates/desktopSmallDefaultPageTemplate";

export const AboutPage = () => {
  return (
    <>
      <MediaQuery minWidth={1224}>
        <DesktopFullDefaultTemplate
          renderStoreInformation={() => <AboutPageContainer />}
        />
      </MediaQuery>

      <MediaQuery minWidth={971} maxWidth={1223}>
        <DesktopMiddleDefaultTemplate
          renderStoreInformation={() => <AboutPageContainer />}
        />
      </MediaQuery>

      <MediaQuery maxWidth={970}>
        <DesktopSmallDefaultTemplate
          renderStoreInformation={() => <AboutPageContainer />}
        />
      </MediaQuery>
    </>
  );
};
