import React from "react";
import MediaQuery from "react-responsive";
import ShippingCostPageContainer from "../../containers/shippingCostPageContainer";
import { DesktopFullDefaultTemplate } from "../templates/defaultPageTemplates/desktopFullDefaultPageTemplate";
import { DesktopMiddleDefaultTemplate } from "../templates/defaultPageTemplates/desktopMiddleDefaultPageTemplate";
import { DesktopSmallDefaultTemplate } from "../templates/defaultPageTemplates/desktopSmallDefaultPageTemplate";

export const ShippingCostPage = () => {
  return (
    <>
      <MediaQuery minWidth={1224}>
        <DesktopFullDefaultTemplate
          renderStoreInformation={() => <ShippingCostPageContainer />}
        />
      </MediaQuery>

      <MediaQuery minWidth={971} maxWidth={1223}>
        <DesktopMiddleDefaultTemplate
          renderStoreInformation={() => <ShippingCostPageContainer />}
        />
      </MediaQuery>

      <MediaQuery maxWidth={970}>
        <DesktopSmallDefaultTemplate
          renderStoreInformation={() => <ShippingCostPageContainer />}
        />
      </MediaQuery>
    </>
  );
};
