import { number, text } from "@storybook/addon-knobs";
import React from "react";
import { ItemInformationBox } from "../../molecules/itemInfomationBox";
import { TextInBoxForSideBar } from "../../molecules/textInBox/index";
import { ItemOuter, RowOuter } from "./element";

export interface Props {
  items: Item[];
  widthRate?: number;
  fontSizeOfItemName: number;
  fontSizeOfItemPrice: number;
  fontSizeOfItemDescription: number;
  col: number;
}

export interface Item {
  name: string;
  imageUrl: string;
  explanation: {
    prices: string[];
    description: string;
  };
}

export const ItemInformations = (props: Props) => {
  const items = props.items;
  while (items.length % props.col !== 0) {
    items.push(getEnptyItem());
  }

  return (
    <>
      {props.items
        .reduce(
          (newItems: Item[][], item: Item) => {
            const newItemsLast = newItems[newItems.length - 1];
            if (newItemsLast.length === props.col) {
              newItems.push([item]);
              return newItems;
            }
            newItemsLast.push(item);
            return newItems;
          },
          [[]]
        )
        .map((rowItems, rowIndex) => {
          return (
            <RowOuter key={rowIndex}>
              {rowItems.map((item, index) => {
                return (
                  <ItemOuter key={index} col={props.col}>
                    {item.name !== "" && (
                      <ItemInformationBox
                        key={index}
                        imageWidth={
                          props.widthRate
                            ? (props.widthRate / props.col) * 0.9
                            : 50
                        }
                        name={item.name}
                        imageUrl={item.imageUrl}
                        explanation={item.explanation}
                        fontSizeOfItemName={props.fontSizeOfItemName}
                        fontSizeOfItemPrice={props.fontSizeOfItemPrice}
                        fontSizeOfItemDescription={
                          props.fontSizeOfItemDescription
                        }
                      />
                    )}
                  </ItemOuter>
                );
              })}
            </RowOuter>
          );
        })}
    </>
  );
};

const getEnptyItem = (): Item => {
  return {
    name: "",
    imageUrl: "",
    explanation: {
      prices: [],
      description: ""
    }
  };
};
