import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Action } from "typescript-fsa";

import {
  asyncActionsFetchEvent,
  EventResponse
} from "../actions/salesActivityPageActions";
import { SalesActivity } from "../presentation/organisms/salesActivity";
import { SalesActivityPageState } from "../states/salesActivityPageState";
import { AppState } from "../store";

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): DispatcherProps {
  return {
    getEvent: () => {
      dispatch(asyncActionsFetchEvent.started({ params: {} }));
      fetch("https://wp.kokiri.jp/wp-json/wp/v2/event")
        .then(response => response.json())
        .then((json: EventResponse) => {
          dispatch(
            asyncActionsFetchEvent.done({
              params: {},
              result: json
            })
          );
        })
        .catch((error: TypeError) => {
          dispatch(
            asyncActionsFetchEvent.failed({
              params: {},
              error
            })
          );
        });
    }
  };
}

function mapStateToProps(appState: AppState): StateProps {
  return {
    salesActivityPageState: appState.salesActivityPage
  };
}

export interface StateProps {
  salesActivityPageState: SalesActivityPageState;
}

interface DispatcherProps {
  getEvent: () => void;
}

export class ContactPageContainer extends React.Component<
  StateProps & DispatcherProps
> {
  constructor(props: StateProps & DispatcherProps) {
    super(props);
  }

  componentDidMount(): void {
    this.props.getEvent();
  }

  render() {
    return <SalesActivity Events={this.props.salesActivityPageState.events} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactPageContainer);
