import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement } from "react";
import { Image } from "../../atoms/Image";
import { Text } from "../../atoms/text/";

import { Sentence } from "../../atoms/sentence";
import {
  OuterAlignCenter,
  OuterAlignLeft,
  OuterAlignRight,
  OuterForSideBar,
  OuterForSnsBox
} from "./element";

export interface Props {
  isActive?: boolean;
  text: string;
  fontSize?: number;
  iconImageUrl?: string;
  handleClick?: () => void;
  color?: string;
}

export interface PropsForSnsBox {
  text: string;
  url?: string;
  fontSize?: number;
  iconSize?: SizeProp;
}

// export interface Props {
//   fontSize?: number;
//   isBold?: boolean;
//   items: Item[];
//   activeItemNo?: number;
// }
//
// export interface Item {
//   id?: string;
//   name?: string;
//   imageUrl?: string;
// }

export const TextInBoxForSideBar = (props: Props) => {
  return (
    <OuterForSideBar onClick={props.handleClick}>
      {!!props.iconImageUrl && (
        <Image
          imageUrl={props.iconImageUrl}
          width={props.fontSize ? props.fontSize + "px" : 22 + "px"}
        />
      )}
      <Text
        fontSize={props.fontSize ? props.fontSize : 22}
        isBold={props.isActive}
      >
        &nbsp;{props.text}
      </Text>
    </OuterForSideBar>
  );
};

export const TextInBoxForSnsLinksBox = (props: PropsForSnsBox) => {
  // facebookとinstagramの時はアイコンで表示する
  let icon: ReactElement<any> | null;
  switch (props.text.toLowerCase()) {
    case "instagram":
      icon = (
        <FontAwesomeIcon
          icon={["fab", "instagram"]}
          size={props.iconSize ? props.iconSize : "sm"}
        />
      );
      break;

    case "facebook":
      icon = (
        <FontAwesomeIcon
          icon={["fab", "facebook"]}
          size={props.iconSize ? props.iconSize : "sm"}
        />
      );
      break;

    default:
      icon = null;
  }
  return (
    <OuterForSnsBox href={props.url}>
      {icon}
      <Text fontSize={props.fontSize ? props.fontSize : 22}>
        &nbsp;{props.text}
      </Text>
    </OuterForSnsBox>
  );
};

export const TextInBoxAlignRight = (props: Props) => {
  return (
    <OuterAlignRight onClick={props.handleClick}>
      <Text
        fontSize={props.fontSize}
        isBold={props.isActive}
        color={props.color}
      >
        {props.text}
      </Text>
    </OuterAlignRight>
  );
};

export const TextInBoxAlignLeft = (props: Props) => {
  return (
    <OuterAlignLeft onClick={props.handleClick}>
      <Sentence
        fontSize={props.fontSize}
        isBold={props.isActive}
        color={props.color}
      >
        {props.text}
      </Sentence>
    </OuterAlignLeft>
  );
};
export const TextInBoxAlignCenter = (props: Props) => {
  return (
    <OuterAlignCenter onClick={props.handleClick}>
      <Text
        fontSize={props.fontSize}
        isBold={props.isActive}
        color={props.color}
      >
        {props.text}
      </Text>
    </OuterAlignCenter>
  );
};
