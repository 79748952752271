import { reducerWithInitialState } from "typescript-fsa-reducers";
import {
  asyncActionsFetchAboutTeaKokiri,
  asyncActionsFetchOwnersProfile
} from "../actions/aboutPageActions";

export interface AboutPageState {
  aboutTeaKokiri: {
    title: string;
    subTitle: string;
    body: string;
  };
  ownersName: {
    title: string;
    subTitle: string;
    body: string;
  };
}

const initialState: AboutPageState = {
  aboutTeaKokiri: {
    title: "",
    subTitle: "",
    body: ""
  },
  ownersName: {
    title: "",
    subTitle: "",
    body: ""
  }
};

export const aboutPageReducer = reducerWithInitialState(initialState)
  /**
   * AboutのURLの取得
   */
  .case(asyncActionsFetchAboutTeaKokiri.started, state => {
    return Object.assign({}, state);
  })
  .case(asyncActionsFetchAboutTeaKokiri.failed, state => {
    // ...
    return Object.assign({}, state);
  })
  .case(asyncActionsFetchAboutTeaKokiri.done, (state, { result }) => {
    const convertedResult = {
      title: result.title.rendered,
      subTitle: result.acf.sub_title,
      body: result.content.rendered
    };
    return Object.assign({}, state, { aboutTeaKokiri: convertedResult });
    // ...
  })
  /**
   * AboutのURLの取得
   */
  .case(asyncActionsFetchOwnersProfile.started, state => {
    return Object.assign({}, state);
  })
  .case(asyncActionsFetchOwnersProfile.failed, state => {
    // ...
    return Object.assign({}, state);
  })
  .case(asyncActionsFetchOwnersProfile.done, (state, { result }) => {
    const convertedResult = {
      title: result.title.rendered,
      subTitle: result.acf.name,
      body: result.content.rendered
    };
    return Object.assign({}, state, { ownersName: convertedResult });
    // ...
  });
