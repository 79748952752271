import React, { ReactElement } from "react";
import { FooterInner, FooterOuter } from "./element";
import { FlexOuter, NavigationOuter, SiteNameOuter } from "./element";

interface Props {
  renderSiteNameLogo: () => ReactElement<any>;
  renderTextNavigation: () => ReactElement<any>;
  renderCopyRight: () => ReactElement<any>;
}

export const DesktopSmallFooterTemplate = (props: Props) => {
  return (
    <FooterOuter>
      <FooterInner>
        <FlexOuter>
          <NavigationOuter>{props.renderTextNavigation()}</NavigationOuter>
        </FlexOuter>
        <FlexOuter>
          <SiteNameOuter>{props.renderSiteNameLogo()}</SiteNameOuter>
        </FlexOuter>
        <FlexOuter>{props.renderCopyRight()}</FlexOuter>
      </FooterInner>
    </FooterOuter>
  );
};
