import actionCreatorFactory from "typescript-fsa";

const actionCreator = actionCreatorFactory();

type Params = object;

export type EventResponse = Array<{
  title: { rendered: string };
  acf: {
    image?: string;
    map?: {
      address: string;
      lat: string;
      lng: string;
    };
    address?: string;
    venue_name?: string;
    description?: string;
    day_1: string;
    day_2?: string;
    day_3?: string;
    day_4?: string;
    day_5?: string;
    day_6?: string;
    day_7?: string;
    time_1?: string;
    time_2?: string;
    time_3?: string;
    time_4?: string;
    time_5?: string;
    time_6?: string;
    time_7?: string;
  };
}>;

export const asyncActionsFetchEvent = actionCreator.async<
  Params,
  EventResponse,
  Error
>("ACTIONS_FETCH_EVENT");
