import { reducerWithInitialState } from "typescript-fsa-reducers";
import { asyncActionsFetchNavigationMenu } from "../actions/headerActions";

interface NavigationMenuData {
  title: string;
  slug: string;
}

export interface HeaderState {
  navigationMenu: NavigationMenuData[];
}

const initialState: HeaderState = {
  navigationMenu: []
};

export const headerReducer = reducerWithInitialState(initialState)
  .case(asyncActionsFetchNavigationMenu.started, state => {
    return Object.assign({}, state);
  })
  .case(asyncActionsFetchNavigationMenu.failed, state => {
    // ...
    return Object.assign({}, state);
  })
  .case(asyncActionsFetchNavigationMenu.done, (state, { result }) => {
    const convertedResult = result.items.map(data => {
      return {
        title: data.title,
        slug: data.url
      };
    });
    return Object.assign({}, state, { navigationMenu: convertedResult });
    // ...
  });
