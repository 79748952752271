import React from "react";
import MediaQuery from "react-responsive";
import { NavigationItem } from "../../../domain/NavigationItems";
import { MenuButton } from "../../atoms/menuButton";
import { SiteNameLogo } from "../../atoms/siteNameLogo";
import { TextNavigation } from "../../atoms/textNavigation";
import { VerticalTextNavigation } from "../../atoms/verticalTextNavigation";
import { DesktopFullHeaderTemplate } from "../../templates/headerTemplates/desktopFullHeaderTemplate";
import { DesktopMiddleHeaderTemplate } from "../../templates/headerTemplates/desktopMiddleHeaderTemplate";
import { DesktopSmallHeaderTemplate } from "../../templates/headerTemplates/desktopSmallHeaderTemplate";
import { StyledLink } from "./element";

interface Props {
  navigationItems: NavigationItem[];
  isActive?: boolean;
  handleClickMenuButton: () => void;
}

export const Header = (props: Props) => {
  return (
    <>
      <MediaQuery minWidth={1224}>
        <DesktopFullHeaderTemplate
          renderSiteNameLogo={() => (
            <StyledLink to={"/"}>
              <SiteNameLogo />
            </StyledLink>
          )}
          renderMenuButton={() => (
            <MenuButton
              handleClick={props.handleClickMenuButton}
              isActive={props.isActive}
            />
          )}
          renderTextNavigation={() => (
            <TextNavigation items={props.navigationItems} />
          )}
          renderVerticalTextNavigation={() => (
            <VerticalTextNavigation
              isActive={props.isActive}
              items={props.navigationItems}
            />
          )}
        />
      </MediaQuery>

      <MediaQuery minWidth={971} maxWidth={1223}>
        <DesktopMiddleHeaderTemplate
          renderSiteNameLogo={() => (
            <StyledLink to={"/"}>
              <SiteNameLogo />
            </StyledLink>
          )}
          renderMenuButton={() => (
            <MenuButton
              handleClick={props.handleClickMenuButton}
              isActive={props.isActive}
            />
          )}
          renderTextNavigation={() => (
            <TextNavigation items={props.navigationItems} />
          )}
          renderVerticalTextNavigation={() => (
            <VerticalTextNavigation
              isActive={props.isActive}
              items={props.navigationItems}
            />
          )}
        />
      </MediaQuery>

      <MediaQuery maxWidth={970}>
        <DesktopSmallHeaderTemplate
          renderSiteNameLogo={() => (
            <StyledLink to={"/"}>
              <SiteNameLogo />
            </StyledLink>
          )}
          renderMenuButton={() => (
            <MenuButton
              handleClick={props.handleClickMenuButton}
              isActive={props.isActive}
            />
          )}
          renderTextNavigation={() => (
            <TextNavigation items={props.navigationItems} />
          )}
          renderVerticalTextNavigation={() => (
            <VerticalTextNavigation
              isActive={props.isActive}
              items={props.navigationItems}
            />
          )}
        />
      </MediaQuery>
    </>
  );
};
