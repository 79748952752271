import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Action } from "typescript-fsa";
import { asyncActionsFetchFooterNavigationMenu } from "../actions/footerActions";
import {
  asyncActionsFetchSnsLinks,
  SnsLinksResponse
} from "../actions/footerActions";
import { TeaLeavesOnMatImage } from "../presentation/atoms/Image";
import { Footer } from "../presentation/organisms/footer";
import { SnsLinksBox } from "../presentation/organisms/snsLinksBox";
import { FooterState } from "../states/footerState";
import { AppState } from "../store";

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): DispatcherProps {
  return {
    getFooterNavigationMenu: () => {
      // const currentState = store.getState()
      dispatch(asyncActionsFetchFooterNavigationMenu.started({ params: {} }));

      fetch("https://wp.kokiri.jp/wp-json/wp-api-menus/v2/menus/7")
        .then(response => response.json())
        .then(
          (json: {
            items: Array<{ id: number; title: string; url: string }>;
          }) => {
            // <- Biz Logic (Async)
            dispatch(
              asyncActionsFetchFooterNavigationMenu.done({
                params: {},
                result: json
              })
            ); // <- App Logic (Sync)
          }
        )
        .catch((error: TypeError) => {
          dispatch(
            asyncActionsFetchFooterNavigationMenu.failed({
              params: {},
              error
            })
          );
        });
    },
    getSnsLinks: () => {
      // const currentState = store.getState()
      dispatch(asyncActionsFetchSnsLinks.started({ params: {} }));

      fetch("https://wp.kokiri.jp/wp-json/wp/v2/sns_links")
        .then(response => response.json())
        .then((json: SnsLinksResponse) => {
          // <- Biz Logic (Async)
          dispatch(
            asyncActionsFetchSnsLinks.done({
              params: {},
              result: json
            })
          ); // <- App Logic (Sync)
        })
        .catch((error: TypeError) => {
          dispatch(
            asyncActionsFetchSnsLinks.failed({
              params: {},
              error
            })
          );
        });
    }
  };
}

function mapStateToProps(appState: AppState): StateProps {
  return {
    footerState: appState.footer
  };
}

export interface StateProps {
  footerState: FooterState;
}

interface DispatcherProps {
  getFooterNavigationMenu: () => void;
  getSnsLinks: () => void;
}

export class FooterContainer extends React.Component<
  StateProps & DispatcherProps
> {
  componentDidMount(): void {
    this.props.getFooterNavigationMenu();
    this.props.getSnsLinks();
  }

  render() {
    return (
      <>
        <TeaLeavesOnMatImage width={"100%"} />
        <SnsLinksBox items={this.props.footerState.snsLinks} />
        <Footer navigationItems={this.props.footerState.footerNavigationMenu} />
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FooterContainer);
